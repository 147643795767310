import { useMap } from 'app/providers/MapProvider';
import mapboxgl from 'mapbox-gl';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCampaign } from 'store/reducers/campaignSlice';
import { LayersName, floatToPercent, host } from '../utils';

export const useVirginMedia = () => {
    const { map } = useMap();
    const { uuidForMap: campaignId } = useSelector(selectCampaign);

    const popup = new mapboxgl.Popup({
        closeButton: false,
    });

    useEffect(() => {
        if (!map || !campaignId) return;

        if (map.getSource('virginMedia')) {
            map.removeLayer(LayersName.VirginMedia);
            map.removeSource('virginMedia');
        }

        // set up source
        map.addSource('virginMedia', {
            type: 'vector',
            tiles: [
                `${host}/api/v1/campaigns/${campaignId}/postcode-broadband/tiles/{z}/{x}/{y}.mvt?group=oa`,
            ],
            minzoom: 0,
            maxzoom: 15,
        });

        // set up point color 
        map.addLayer({
            id: LayersName.VirginMedia,
            type: 'circle',
            source: 'virginMedia',
            'source-layer': 'default',
            filter: ['==', 'has_virgin_media', true],
            paint: {
                'circle-color': 'pink',
                'circle-opacity': 0.9,
                'circle-radius': {
                    'base': 1.75,
                    'stops': [
                        [12, 2],
                        [22, 90]
                    ]
                },
                'circle-stroke-width': 0.5,
                'circle-stroke-color': 'black',
            },
            layout: {
                'visibility': 'none'
            },
            minzoom: 15,
            maxzoom: 24,
        });

        // click on subpolygon
        map.on('click', LayersName.VirginMedia, (e) => {
            // @ts-ignore
            const props = e.features[0].properties;
            console.log(props);

            const has_virgin_media = props?.has_virgin_media_confidence;

            const titleHtml = `<h1>Virgin media confidence: <strong>${floatToPercent(+has_virgin_media)}</strong></h1>`;
            const html = `<div class='popup'>
          ${titleHtml}
          </div>
        `;

            popup.setLngLat(e.lngLat).setHTML(html).addTo(map);

        });

        map.on('mouseenter', LayersName.VirginMedia, () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', LayersName.VirginMedia, () => {
            map.getCanvas().style.cursor = '';
        });
    }, [map, campaignId]);
};