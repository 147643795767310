import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQueryWithAuth } from '../createBaseQueryWithAuth';

export interface Company {

    extra_info: any,
    created_at: string;
    updated_at: string;
    uuid: string;
    name: string;
    users: any,
    regions: any,
}

export const companiesAPI = createApi({
    reducerPath: 'companiesAPI',
    baseQuery: createBaseQueryWithAuth(`${process.env.REACT_APP_HERMES_API_URL}/api/v1`),
    tagTypes: ['Create', 'Delete', 'Update'],
    endpoints: (build) => ({
        getAllCompanies: build.query<Company[], void>({
            query: () => ({
                url: '/companies',
                method: 'GET',
            }),
        }),

        getSalesStats: build.query<any, { company_uuid: string, params?: any; }>({
            query: ({ company_uuid, params }) => ({
                url: `/companies/${company_uuid}/stats/sales?${params}`,
                method: 'GET',
            }),
        }),

        getChurnStats: build.query<any, { company_uuid: string, params?: any; }>({
            query: ({ company_uuid, params }) => ({
                url: `/companies/${company_uuid}/stats/churn?${params}`,
                method: 'GET',
            }),
        }),
        getDoorKnockedStats: build.query<any, { company_uuid: string, params?: any; }>({
            query: ({ company_uuid, params }) => ({
                url: `/companies/${company_uuid}/stats/doors-knocked?${params}`,
                method: 'GET',
            }),
        }),
        getExistingCompanyCustomers: build.query<any, { company_uuid: string, region_uuid?: string; }>({
            query: ({ company_uuid }) => ({
                url: `companies/${company_uuid}/customers`,
                method: 'GET',
            }),
        }),
    })
});