import { Tag } from 'antd';
import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { format } from 'date-fns';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { penModelAPI } from 'services/PenModel';
import { addUuid } from 'store/reducers/campaignSlice';
import styles from './styles.module.scss';

interface Props {
    record: {
        key: string;
        extra_info: any;
        created_at: string;
        updated_at: string;
        uuid: string;
        region_uuid: string;
        pen_model_uuid: string;
        name: string;
    };
}

export const ExpandedRowContent: React.FC<Props> = ({ record }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const { data: penModels = [], } = penModelAPI.useGetAllPenModelsQuery();

    const { created_at, pen_model_uuid, updated_at, uuid } = record;
    const penModel = penModels.find((el) => el.uuid === pen_model_uuid);

    return <div className={styles.expandedTable}>
        <div className={styles.dataGrid}>
            <div className={classNames('text-primary', 'text-500')}><span className='text-tertiary text-400'>Created:</span> {format(new Date(created_at), 'dd.MM.yyyy')}</div>
            <div className={classNames('text-primary', 'text-500')}><span className='text-tertiary text-400'>Updated:</span> {format(new Date(updated_at), 'dd.MM.yyyy')}</div>
            <div className={classNames('text-primary', 'text-500')}><span className='text-tertiary text-400'>Pen model name:</span> {penModel ? <Tag color='var(--bg-brand-solid)'>{penModel?.name}</Tag> : 'No data'}</div>
            <div className={classNames('text-primary', 'text-500')}><span className='text-tertiary text-400'>Pen model type:</span> {penModel ? <Tag color='var(--bg-brand-solid)'>{penModel?.type}</Tag> : 'No data'}</div>
        </div>

        <div className={styles.actions}>
            <Button
                size={ButtonSize.MD}
                palette={ButtonPalette.SECONDARY_GRAY}
                onClick={() => {
                    dispatch(addUuid({ uuid }));
                    navigate(`${pathname}/${uuid}`);
                }}>Assign workers</Button>
        </div>
    </div>;
};