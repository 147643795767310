import { useMap } from 'app/providers/MapProvider';
import { EmptyCardNoData } from 'elements/EmptyState';
import { Skeleton } from 'elements/Skeleton';
import mapboxGl from 'mapbox-gl';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { regionsAPI } from 'services/Regions/RegionsService';
import { selectRegion } from 'store/reducers/regionSlice';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import styles from './Map.module.scss';

(mapboxGl as any).workerClass = MapboxWorker;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
mapboxGl.accessToken = process.env.REACT_APP_MAP_TOKEN!;

export const RegionsMap = () => {
    const { setDraw, setMap, map } = useMap();
    const mapRef = useRef<HTMLDivElement>(null);
    const { data: regions = [], isLoading } = regionsAPI.useGetAllRegionsQuery();
    const { uuid: regionUuid, } = useSelector(selectRegion);

    const source = {
        'type': 'Polygon',
        'coordinates': [
            regions.map((el) => el.geometry.coordinates).flat(3)
        ]
    };

    const fitBoundToSelectedPolygon = (map: mapboxGl.Map | null) => {
        if (!map) return;

        // @ts-ignore
        const coordinates = map.getSource('bigPolly')._data.coordinates;

        // Create a 'LngLatBounds' with both corners at the first coordinate
        // Fist point of first polygon
        const bounds = new mapboxGl.LngLatBounds(
            [...coordinates[0][0], ...coordinates[0][1]] as [number, number, number, number,]
        );

        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of coordinates) {
            for (let i = 0; i < coord.length; i++) {
                bounds.extend(coord[i] as never as [number, number]);
            }
        }
        map.fitBounds(bounds, {
            padding: 80
        });
    };

    useEffect(() => {
        if (!mapRef.current) return;
        if (!regions.length) return;

        const map = new mapboxGl.Map({
            container: mapRef.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            bounds: [-3.556744917775376, 50.459380291269525, 1.7584027519666279, 52.42691321915794],
            zoom: 6,
            minZoom: 5.5 // zoom < 5.5 caused 404s. 
        });

        if (!map) return;

        map.on('load', async () => {

            regions.forEach((el) => {
                map.addSource(`region_${el.uuid}_source`, {
                    'type': 'geojson',
                    'data': el.geometry
                });

                map.addLayer({
                    'id': `region_${el.uuid}`,
                    'type': 'fill',
                    'source': `region_${el.uuid}_source`,
                    'layout': {},
                    'paint': {
                        'fill-antialias': true,
                        'fill-color': '#596ff9',
                        'fill-opacity': 0.4,
                        'fill-outline-color': '#596ff9'
                    }

                });
            });

            map.addSource('bigPolly', {
                'type': 'geojson',
                // @ts-ignore
                'data': source
            });

            setMap(map);
            fitBoundToSelectedPolygon(map);
        });

        return () => {
            setDraw(null);
            setMap(null);
        };
    }, [regions]);

    useEffect(() => {
        if (!map) return;
        setTimeout(() => {
            map.resize();

        }, 200);
    }, [map]);

    useEffect(() => {
        if (!map || !regionUuid || !regions.length) return;
        regions.forEach((el) => {
            map.setPaintProperty(`region_${el.uuid}`, 'fill-color', '#596ff9');
            map.setPaintProperty(`region_${el.uuid}`, 'fill-outline-color', '#596ff9');
            map.setPaintProperty(`region_${el.uuid}`, 'fill-opacity', 0.4);

        });
        map.setPaintProperty(`region_${regionUuid}`, 'fill-color', '#596ff9');
        map.setPaintProperty(`region_${regionUuid}`, 'fill-outline-color', '#596ff9');
        map.setPaintProperty(`region_${regionUuid}`, 'fill-opacity', 0.9);

    }, [map, regionUuid, regions]);

    if (isLoading) {
        return <Skeleton.ChartSkeleton />;
    }

    if (regions.length === 0) {
        return <EmptyCardNoData message="No region was assigned" />;
    }

    return (
        <>
            <div className={styles.mapContainer} data-testid='Map'>
                <div className={styles.map} id='map' ref={mapRef} />
            </div>
        </>
    );
};