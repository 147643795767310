import { yupResolver } from '@hookform/resolvers/yup';
import { Switch } from 'antd';
import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Input } from 'elements/Input';
import { NotificationType, useNotification } from 'hooks/useNotification';
import { CampaignsFullExport } from 'models/Campaigns';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { campaignRunAPI } from 'services/Campaigns/CampaignsRunService';
import { campaignAPI } from 'services/Campaigns/CampaignsService';
import { selectCampaign } from 'store/reducers/campaignSlice';
import { KnockListInputs, defaultValuesKnockList, validationSchemaKnockList } from '../FormValidation';
import styles from './styles.module.scss';

export const KnockListForm = () => {
    const { data: campaigns = [] as CampaignsFullExport } = campaignAPI.useGetAllCampaignsQuery();
    const { uuidForExport: uuid } = useSelector(selectCampaign);
    const campaign = campaigns.find((el) => el.uuid === uuid);

    const [createExport, { error: createExportError, isLoading, isSuccess, isError }] = campaignRunAPI.useCreateCampaignRunExportMutation();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: defaultValuesKnockList,
        resolver: yupResolver(validationSchemaKnockList),
    });

    const onSubmit = async (data: {
        chunk_count: number,
        min_property_count?: number | null;
    }) => {
        if (!campaign || !uuid) return;

        try {
            await createExport({
                body: {
                    campaign_uuid: uuid,
                    name: `${campaign?.name}_${Date.now()}`,
                    chunk_count: data.chunk_count,
                    min_property_count: data.min_property_count || 160
                }
            });
            // refetch();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Error creating user:', error, createExportError);
        }
    };

    useNotification({ type: NotificationType.SUCCESS, trigger: isSuccess, text: 'Email export request for selected campaign has been queued!' });
    useNotification({ type: NotificationType.ERROR, trigger: isError, text: 'An error occurred while generating knock list. If the issue persists, contact support for assistance.' });

    watch(KnockListInputs.MIN_COUNT);
    watch(KnockListInputs.NUMBER_SALES_PEOPLE);

    return <div className={styles.grid}>

        <Input
            type='number'
            label='Number of Sales People'
            placeholder='Type a number here'
            error={{
                message: errors.chunk_count?.message
            }}
            registerProps={
                { ...register(KnockListInputs.NUMBER_SALES_PEOPLE, { required: true }) }
            }
        />
        <Input
            type='number'
            label='Number of Doors Daily'
            placeholder='Type a number here'
            min={160}
            error={{
                message: errors.min_property_count?.message
            }}
            registerProps={
                { ...register(KnockListInputs.MIN_COUNT) }
            }
        />
        <div className={classNames(styles.switch, styles.switchDisabled)}>
            <Switch disabled defaultChecked onChange={() => { }} />
            <div>
                <p className={classNames('text-md', 'text-500', 'text-secondary')}>Include MDUs&#39;</p>
                <span className={classNames('text-md', 'text-400', 'text-tertiary', styles.definition)}>A commercial or residential building with multiple offices or apartments</span>
            </div>

        </div>

        <div className={classNames(styles.switch, styles.switchDisabled)}>
            <Switch disabled onChange={() => { }} />
            <div>
                <p className={classNames('text-md', 'text-500', 'text-secondary')}>Include Businesses</p>
                <span className={classNames('text-md', 'text-400', 'text-tertiary', styles.definition)}>A commercial building</span>
            </div>
        </div>
        <Button
            className={styles.btn}
            size={ButtonSize.MD}
            palette={ButtonPalette.PRIMARY}
            isLoading={isLoading}
            onClick={handleSubmit(onSubmit)}>Create knock list</Button>

    </div>;
};