import classNames from 'classnames';
import { AddCampaign } from './AddCampaign';
import { RemoveRegion } from './RemoveRegion';
import styles from './styles.module.scss';

export const HeaderControls = () => {
    return <div className={classNames(styles.controls, styles.headerBtn)}>
        <AddCampaign />
        <RemoveRegion />
    </div>;
};