import { useMap } from 'app/providers/MapProvider';
import classNames from 'classnames';
import { Modal } from 'components/Modal';
import { ModalBody } from 'components/Modal/ModalBody';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { floatToPercent } from 'components/RegionMap/utils';
import { Skeleton } from 'elements/Skeleton';
import { useAuth } from 'hooks/useAuth';
import mapboxGl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { UsersExport } from 'models/HercUsers';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { campaignRunAPI } from 'services/Campaigns/CampaignsRunService';
import { usersAPI } from 'services/Hercules/HerculesUsersService';
import { selectRegion } from 'store/reducers/regionSlice';
import { chunkColours } from 'utils/colours';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import styles from './Map.module.scss';

(mapboxGl as any).workerClass = MapboxWorker;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
mapboxGl.accessToken = process.env.REACT_APP_MAP_TOKEN!;

const host = process.env.REACT_APP_HERMES_API_URL;

export const RoutesModal = ({ runUuid, onClose }: any) => {
    // const [runUuid, setRunUuid] = useState<string>('');
    const { data: chunks = [], isLoading, refetch } = campaignRunAPI.useGetChunksInRunQuery({ run_uuid: runUuid }, {
        skip: !runUuid
    });
    const { bounds } = useSelector(selectRegion);

    const { data: users = [] as UsersExport, isLoading: isUsersLoading } = usersAPI.useGetAllUsersQuery(undefined);

    const { setDraw, setMap, map } = useMap();
    const { token } = useAuth();

    const mapRef = useRef<HTMLDivElement>(null);
    const popup = new mapboxGl.Popup({
        closeButton: false,
    });
    useEffect(() => {
        refetch();
        if (!mapRef.current) return;

        const map = new mapboxGl.Map({
            container: mapRef.current,
            center: [-1.748506, 52.380998],
            transformRequest: (url: any): any => {
                if (url.startsWith(host)) {
                    return {
                        url,
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    };
                }
            },
            style: {
                version: 8,
                sources: {
                    base: {
                        type: 'raster',
                        tiles: ['https://os.fibreplanner.io/z-{z}_x-{x}_y-{y}'],
                        maxzoom: 20,
                        tileSize: 256,
                    },
                },
                layers: [
                    {
                        id: 'base-layer',
                        type: 'raster',
                        source: 'base',
                        minzoom: 0,
                        maxzoom: 24,
                    },
                ],
            },
            bounds: [-3.556744917775376, 50.459380291269525, 1.7584027519666279, 52.42691321915794],
            maxBounds: [-10.731977871337193, 49.59411301175666, 1.9010451201391163, 61.32814923895637],
            zoom: 6,
            minZoom: 5.5 // zoom < 5.5 caused 404s. 
        });

        map.on('load', async () => {
            setMap(map);

            bounds && map.fitBounds(bounds, {
                padding: 20
            });
        });

        return () => {
            setDraw(null);
            setMap(null);
        };
    }, []);

    useEffect(() => {
        if (!map) return;
        chunks.forEach((el) => {
            map.addSource(`chunk-source_${el.chunk_id}`, {
                type: 'vector',
                tiles: [
                    `${host}/api/v1/campaign-runs/${el.run_uuid}/chunks/${el.chunk_id}/route/tiles/{z}/{x}/{y}.mvt`,
                ],
            });

            map.addLayer({
                id: `chunk-layer_${el.chunk_id}`,
                type: 'circle',
                source: `chunk-source_${el.chunk_id}`,
                'source-layer': 'default',
                paint: {
                    'circle-color': chunkColours[`Worker_${el.chunk_id}`],
                    'circle-opacity': 0.9,
                    'circle-radius': {
                        'base': 1.75,
                        'stops': [
                            [12, 2],
                            [22, 90]
                        ]
                    },
                    'circle-stroke-width': 0.5,
                    'circle-stroke-color': 'black'
                },
                layout: {
                    'visibility': 'visible'
                }
            });

            // click on subpolygon
            map.on('click', `chunk-layer_${el.chunk_id}`, (e) => {
                // @ts-ignore
                const props = e.features[0].properties!;

                const uprn = props?.uprn;
                const classification_code = props?.classification_code;
                const building_number = props?.building_number;
                const postcode = props?.postcode;
                const worker_uuid = props?.worker_uuid;
                const predicated_pen = props?.predicated_pen;
                const worker = users.find((item) => item.uuid === worker_uuid)?.username;

                const titleHtml = `<h1>UPRN: <strong>${uprn}</strong></h1>`;
                const propsHtml = `
                    <h2>Classification Code:<strong> ${classification_code}</strong></h2>
                    <h2>Chunk ID:<strong> ${typeof el.chunk_id === 'number' ? el.chunk_id : '-'}</strong></h2>
                    <h2>Worker:<strong> ${worker}</strong></h2>
                    <h2>Building Number: <strong>${building_number || '-'}</strong></h2>
                    <h2>Postcode: <strong>${postcode}</strong></h2>
                    <h2>Predicated Pen: <strong>${floatToPercent(predicated_pen)}</strong></h2>
                    `;
                const html = `<div class='popup'>
                    ${titleHtml}
                    ${propsHtml}
                    </div>
                    `;

                popup.setLngLat(e.lngLat)
                    .setHTML(html)
                    .addTo(map);
            });

            map.on('mouseenter', `chunk-layer_${el.chunk_id}`, () => {
                map.getCanvas().style.cursor = 'pointer';
            });

            map.on('mouseleave', `chunk-layer_${el.chunk_id}`, () => {
                map.getCanvas().style.cursor = '';
            });
        });

    }, [map, chunks]);

    return (
        <Modal className={styles.modal} onClose={() => onClose()}>
            <ModalHeader>
                Routes
            </ModalHeader>
            <ModalBody className={styles.modalBody}>
                <div className={classNames(styles.mapContainer)} data-testid='Map'>
                    <div className={styles.map} id='map' ref={mapRef} />
                </div>
                <ul className={styles.list}>
                    {(isLoading || isUsersLoading) && <Skeleton />}

                    {!!users.length && chunks.map((el) => {
                        const worker = users.find((item) => item.uuid === el.worker_uuid);

                        return <li key={el.worker_uuid} className={classNames('text-primary', styles.worker)}>
                            <div className={styles.workerColor} style={{
                                backgroundColor: chunkColours[`Worker_${el.chunk_id}`]
                            }}>
                            </div>
                            {worker?.username || <span className={classNames('text-tertiary')}>Not assigned</span>}
                        </li>;
                    })}
                </ul>
            </ModalBody>
        </Modal>
    );
};