import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { RemoveRegionModal } from 'pages/CampaignPage/RemoveRegionModal';
import { useState } from 'react';
import { usersAPI } from 'services/Hercules/HerculesUsersService';
import styles from './styles.module.scss';

export const RemoveRegion = () => {
    const [isModalShown, setModalShown] = useState(false);

    const { data: currentUser = [] }: any = usersAPI.useGetCurrentUserQuery();

    if (!currentUser.is_admin && !currentUser.is_superuser) return <></>;

    return <>
        {(currentUser.is_admin || currentUser.is_superuser) && <Button size={ButtonSize.MD}
            palette={ButtonPalette.SECONDARY_GRAY}
            onClick={() => setModalShown(true)}
            className={styles.headerBtn}
        >
            Remove region
        </Button>}

        {isModalShown && <RemoveRegionModal onClose={() => setModalShown(false)} />}

    </>;
};