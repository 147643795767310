import { Button } from 'components/Button';
import { ButtonPalette, ButtonSize } from 'components/Button/Button';
import { Icons } from 'icons';
import styles from '../styles.module.scss';

export const Buttons = () => {
    return (
        <table className={styles.table}>
            <tr>
                <th>
                    size
                </th>
                <th>
                    palette
                </th>
                <th>
                    normal
                </th>
                <th>
                    loading
                </th>
                <th>
                    disabled
                </th>
            </tr>
            <tr>
                <td rowSpan={2}>
                    <code>
                        size=&quot;{ButtonSize.LG}&quot;
                    </code>
                </td>
                <td>
                    <code>
                        palette=&quot;{ButtonPalette.PRIMARY}&quot;
                    </code>
                </td>
                <td>

                    <Button
                        palette={ButtonPalette.PRIMARY}
                        size={ButtonSize.LG}
                    >
                        Text
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.PRIMARY}
                        size={ButtonSize.LG}
                        isLoading
                    >
                        isLoading
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.PRIMARY}
                        size={ButtonSize.LG}
                        isDisabled
                    >
                        isDisabled
                    </Button>
                </td>
            </tr>
            <tr>
                {/* <td>
                    <code>
                        size=&quot;{ButtonSize.LG}&quot;
                    </code>
                </td> */}
                <td>
                    <code>
                        palette=&quot;{ButtonPalette.PRIMARY}&quot;
                    </code>
                </td>
                <td>

                    <Button
                        palette={ButtonPalette.PRIMARY}
                        size={ButtonSize.LG}
                    >
                        <Icons.AddUser />
                        Text
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.PRIMARY}
                        size={ButtonSize.LG}
                        isLoading
                    >
                        isLoading
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.PRIMARY}
                        size={ButtonSize.LG}
                        isDisabled
                    >
                        isDisabled
                    </Button>
                </td>
            </tr>
            <tr>
                <td>
                    <code>
                        size=&quot;{ButtonSize.LG}&quot;
                    </code>
                </td>
                <td>
                    <code>
                        palette=&quot;{ButtonPalette.SECONDARY_COLOR}&quot;
                    </code>
                </td>
                <td>

                    <Button
                        palette={ButtonPalette.SECONDARY_COLOR}
                        size={ButtonSize.LG}
                    >
                        Text
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.SECONDARY_COLOR}
                        size={ButtonSize.LG}
                        isLoading
                    >
                        isLoading
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.SECONDARY_COLOR}
                        size={ButtonSize.LG}
                        isDisabled
                    >
                        isDisabled
                    </Button>
                </td>
            </tr>
            <tr>
                <td>
                    <code>
                        size=&quot;{ButtonSize.LG}&quot;
                    </code>
                </td>
                <td>
                    <code>
                        palette=&quot;{ButtonPalette.SECONDARY_GRAY}&quot;
                    </code>
                </td>
                <td>

                    <Button
                        palette={ButtonPalette.SECONDARY_GRAY}
                        size={ButtonSize.LG}
                    >
                        Text
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.SECONDARY_GRAY}
                        size={ButtonSize.LG}
                        isLoading
                    >
                        isLoading
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.SECONDARY_GRAY}
                        size={ButtonSize.LG}
                        isDisabled
                    >
                        isDisabled
                    </Button>
                </td>
            </tr>

            <tr>
                <td>
                    <code>
                        size=&quot;{ButtonSize.LG}&quot;
                    </code>
                </td>
                <td>
                    <code>
                        palette=&quot;{ButtonPalette.TERTIARY_COLOR}&quot;
                    </code>
                </td>
                <td>

                    <Button
                        palette={ButtonPalette.TERTIARY_COLOR}
                        size={ButtonSize.LG}
                    >
                        Text
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.TERTIARY_COLOR}
                        size={ButtonSize.LG}
                        isLoading
                    >
                        isLoading
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.TERTIARY_COLOR}
                        size={ButtonSize.LG}
                        isDisabled
                    >
                        isDisabled
                    </Button>
                </td>
            </tr>
            <tr>
                <td>
                    <code>
                        size=&quot;{ButtonSize.LG}&quot;
                    </code>
                </td>
                <td>
                    <code>
                        palette=&quot;{ButtonPalette.TERTIARY_GRAY}&quot;
                    </code>
                </td>
                <td>

                    <Button
                        palette={ButtonPalette.TERTIARY_GRAY}
                        size={ButtonSize.LG}
                    >
                        Text
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.TERTIARY_GRAY}
                        size={ButtonSize.LG}
                        isLoading
                    >
                        isLoading
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.TERTIARY_GRAY}
                        size={ButtonSize.LG}
                        isDisabled
                    >
                        isDisabled
                    </Button>
                </td>
            </tr>
            <tr>
                <td>
                    <code>
                        size=&quot;{ButtonSize.LG}&quot;
                    </code>
                </td>
                <td>
                    <code>
                        palette=&quot;{ButtonPalette.PRIMARY_ERROR}&quot;
                    </code>
                </td>
                <td>

                    <Button
                        palette={ButtonPalette.PRIMARY_ERROR}
                        size={ButtonSize.LG}
                    >
                        Text
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.PRIMARY_ERROR}
                        size={ButtonSize.LG}
                        isLoading
                    >
                        isLoading
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.PRIMARY_ERROR}
                        size={ButtonSize.LG}
                        isDisabled
                    >
                        isDisabled
                    </Button>
                </td>
            </tr>
            <tr>
                <td>
                    <code>
                        size=&quot;{ButtonSize.LG}&quot;
                    </code>
                </td>
                <td>
                    <code>
                        palette=&quot;{ButtonPalette.SECONDARY_ERROR}&quot;
                    </code>
                </td>
                <td>

                    <Button
                        palette={ButtonPalette.SECONDARY_ERROR}
                        size={ButtonSize.LG}
                    >
                        Text
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.SECONDARY_ERROR}
                        size={ButtonSize.LG}
                        isLoading
                    >
                        isLoading
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.SECONDARY_ERROR}
                        size={ButtonSize.LG}
                        isDisabled
                    >
                        isDisabled
                    </Button>
                </td>
            </tr>
            <tr>
                <td>
                    <code>
                        size=&quot;{ButtonSize.LG}&quot;
                    </code>
                </td>
                <td>
                    <code>
                        palette=&quot;{ButtonPalette.TERTIARY_ERROR}&quot;
                    </code>
                </td>
                <td>

                    <Button
                        palette={ButtonPalette.TERTIARY_ERROR}
                        size={ButtonSize.LG}
                    >
                        Text
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.TERTIARY_ERROR}
                        size={ButtonSize.LG}
                        isLoading
                    >
                        isLoading
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.TERTIARY_ERROR}
                        size={ButtonSize.LG}
                        isDisabled
                    >
                        isDisabled
                    </Button>
                </td>
            </tr>
        </table>);
};