import classNames from 'classnames';
import { ROUTES } from 'constants/routes';
import { NavItem } from 'elements/NavItem';
import { Skeleton } from 'elements/Skeleton';
import { ThemeSwitcher } from 'elements/ThemeSwitcher';
import { User } from 'elements/User';
import { useAuth } from 'hooks/useAuth';
import { Icons } from 'icons';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { usersAPI } from 'services/Hercules/HerculesUsersService';
import styles from './Navigation.module.scss';
import { LogoIcon } from './icon/LogoIcon';

interface Props {
}

const Navigation: React.FC<Props> = () => {
    const { token, setToken } = useAuth();
    const { data: currentUser, isLoading: getCurrentUserIsLoading, refetch } = usersAPI.useGetCurrentUserQuery();

    useEffect(() => {
        refetch();
    }, [token]);

    if (!token) {
        return <Skeleton />;
    }
    return <div data-testid="navigation" className={styles.navigation}>
        <div className={styles.navigationTop}>
            <Link to={ROUTES.ROOT}>
                <LogoIcon />
            </Link>

            <ul className={classNames(styles.navList)}>
                <li>
                    <NavItem data-testid="nav-home"
                        link={ROUTES.ROOT}>
                        <Icons.Dashboard />
                        Dashboard
                    </NavItem>
                </li>
                <li>
                    <NavItem data-testid="nav-home"
                        link={ROUTES.ANALYTICS}>
                        <Icons.Analytics />

                        Analytics
                    </NavItem>
                </li>
                <li>
                    <NavItem data-testid="nav-home"
                        link={ROUTES.TELEMARKETING}>
                        <Icons.Analytics2 />

                        Telesales
                    </NavItem>
                </li>
                {(currentUser?.is_admin || currentUser?.is_superuser) && <li>
                    <NavItem data-testid="nav-home"
                        link={ROUTES.CREATE_USER}>
                        <Icons.Users />

                        Users
                    </NavItem>
                </li>}
            </ul>
        </div>
        <div></div>
        <div className={styles.navigationBottom}>
            <ul className={classNames(styles.navList)}>
                {/* <li>
                    <NavItem data-testid="nav-home"
                        link={ROUTES.HELP}>
                        <Icons.Help />

                        Help
                    </NavItem>
                </li> */}
                <li>
                    <ThemeSwitcher />
                </li>
                <li>
                    <NavItem data-testid="nav-home"
                        link={ROUTES.SETTINGS}>
                        <Icons.Settings />

                        Settings
                    </NavItem>
                </li>
                <li>
                    <div className={styles.logout} onClick={() => setToken!('')}>
                        <Icons.Logout />
                        Log out
                    </div>
                </li>
            </ul>
            <hr className={styles.separator} />
            {getCurrentUserIsLoading && <Skeleton />}

            {currentUser?.username && <User
                name={currentUser?.username}
                email={currentUser?.email} />
            }
        </div>
    </div >;
};

export default Navigation;