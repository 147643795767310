import mapboxGl from 'mapbox-gl';
import { Bounce, toast } from 'react-toastify';

/**
 * Fits the map view to the bounds of a selected polygon region based on the given `uuid`. If the polygon coordinates are invalid, an error toast notification is shown.
 *
 * @param {mapboxGl.Map | null} map - The Mapbox map instance to adjust the view.
 * @param {string} uuid - The unique identifier for the polygon's data source on the map.
 *
 * @returns {mapboxGl.LngLatBounds | undefined} The calculated bounding box for the selected polygon, or `undefined` if an error occurs.
 *
 * @example
 * const map = new mapboxGl.Map({ ... });
 * fitBoundToSelectedPolygon(map, 'region123');
 */

export const fitBoundToSelectedPolygon = (map: mapboxGl.Map | null, uuid: string) => {
    if (!map) return;

    // @ts-ignore
    const coordinates = map.getSource(`region_${uuid}_source`)._data.coordinates;

    if (!coordinates[0]?.length) {
        toast.error('Region created with errors. Contact support for assistance.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Bounce,
        });
        return;
    };

    // Create a 'LngLatBounds' with both corners at the first coordinate
    // Fist point of first polygon
    const bounds = new mapboxGl.LngLatBounds(
        [...coordinates[0][0][0], ...coordinates[0][0][1]] as [number, number, number, number,]
    );

    // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
    for (const coord of coordinates[0]) {
        for (let i = 0; i < coord.length; i++) {
            bounds.extend(coord[i] as never as [number, number]);
        }
    }
    map.fitBounds(bounds, {
        padding: 20
    });

    return bounds;
};