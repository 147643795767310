import { useMap } from 'app/providers/MapProvider';
import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';

export const MapWrapper = ({ children }: any) => {
    const { map } = useMap();
    const [isFullScreen, setFullScreen] = useState(false);

    useEffect(() => {
        if (!map) return;

        map.resize();

    }, [map, isFullScreen]);

    return <>
        <div className={classNames(styles.mapWrapper, {
            [styles.backdrop]: isFullScreen
        })}>
            <div className={classNames(styles.modalView, {
                [styles.fullScreen]: isFullScreen,
                [styles.isModalOpen]: isFullScreen
            })}>
                <div className={classNames(styles.mapContainer)}>
                    <div className={styles.map}>
                        {children}
                    </div>
                    <Button size={ButtonSize.LG}
                        palette={ButtonPalette.SECONDARY_COLOR}
                        className={styles.btnFullScreen}
                        onClick={() => {
                            if (!isFullScreen) {
                                setFullScreen(true);
                            } else {
                                setFullScreen(false);
                            }
                        }}>
                        {!isFullScreen ? 'Full screen' : 'Close'}
                    </Button>

                </div>

            </div>
        </div>
    </>;
};