import { NotificationType, useNotification } from 'hooks/useNotification';
import { campaignAPI } from 'services/Campaigns/CampaignsService';

interface Props {
    refetch?: any;
}

export const useDeleteCampaign = ({ refetch = () => { } }: Props) => {

    const [deleteCampaign, { isError, isSuccess }] = campaignAPI.useDeleteCampaignMutation();

    const handleDeleteCampaign = async (campaign_uuid: string) => {
        try {
            await deleteCampaign({ campaign_uuid });
            refetch();
        } catch (err) {
            console.error(err);
        }
    };

    useNotification({ type: NotificationType.SUCCESS, trigger: isSuccess, text: 'Campaign has been successfully removed!' });
    useNotification({ type: NotificationType.ERROR, trigger: isError, text: 'An error occurred while removing campaign. If the issue persists, contact support for assistance.' });

    return {
        handler: (campaign_uuid: string) => handleDeleteCampaign(campaign_uuid),
        isError,
        isSuccess
    };
};