import { ROUTES } from 'constants/routes';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearToken, setToken } from 'store/reducers/tokenSlice';
import { RootState } from './../store/store';

/**
 * useAuth hook
 *
 * The useAuth hook is a custom React hook that manages user authentication in a React-Redux application
 * 
 * @param {T} value - the current state value that you want to store in the ref and keep up-to-date
 *
 * @returns { token: string | null; setToken: (newToken: string | null) => void; } 
 * 
 * 
*/

export const useAuth = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const token = useSelector((state: RootState) => state.token.value);

    const updateToken = useCallback(
        (newToken: string | null) => {
            if (newToken) {
                dispatch(setToken(newToken));
                navigate(ROUTES.ROOT);
            } else {
                dispatch(clearToken());
                navigate(ROUTES.SIGN_IN);
            }
        },
        [dispatch, navigate],
    );

    return { token, setToken: updateToken };
};
