import { ConfigProvider, Table, Tag, Tooltip } from 'antd';
import classNames from 'classnames';
import { Button } from 'components/Button';
import { ButtonPalette, ButtonSize } from 'components/Button/Button';
import { BodyPage } from 'elements/BodyPage';
import { HeaderPage } from 'elements/HeaderPage';
import { Input } from 'elements/Input';
import { User } from 'elements/User';
import { NotificationType, useNotification } from 'hooks/useNotification';
import { Icons } from 'icons';
import { UsersExport } from 'models/HercUsers';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { usersAPI } from 'services/Hercules/HerculesUsersService';
import { closeDrawer } from 'store/reducers/drawerSlice';
import { deletePostcode, deleteUprn } from 'store/reducers/leadSlice';
import { designToken, paginationDesignToken, tableDesignToken } from 'utils/designToken';
import { AddRegionPopup } from './AddRegionPopup';
import { CreateUserPopup } from './CreateUserPopup';
import styles from './styles.module.scss';
import { getIcon } from './util/getIcon';

export const TeamPage = () => {
    const [showNewUserPopup, setNewUserPopupShow] = useState(false);
    const [showAddRegionPopup, setAddRegionPopupShow] = useState(false);
    const [editUserUuid, setEditUserUuid] = useState();
    const [deleteUserUuid, setDeleteUserUuid] = useState('');
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {

        dispatch(deletePostcode());
        dispatch(deleteUprn());
    }, []);

    const { data: users = [] as UsersExport, refetch } = usersAPI.useGetAllUsersQuery();
    const [deleteUser, { error: deleteUserError, isSuccess: isDeleteUserSuccess, isError: isDeleteUserError }] = usersAPI.useDeleteUserMutation();

    const data = users?.map((el) => ({
        ...el,
        key: el.uuid
    }));

    useEffect(() => {
        dispatch(closeDrawer());
    }, []);

    useNotification({ type: NotificationType.SUCCESS, trigger: isDeleteUserSuccess, text: 'User has been successfully deleted!' });
    useNotification({ type: NotificationType.ERROR, trigger: isDeleteUserError, text: 'An error occurred while deleting user. If the issue persists, contact support for assistance.' });

    const columns: any = [
        {
            key: 'username',
            dataIndex: 'username',
            title: 'Name',
            width: '20%',
            render: (username: string) => <User name={username} />,
            sorter: (data: { username: string; }, data2: { username: string; }) => data.username.localeCompare(data2.username),
            sortIcon: ({ sortOrder }: any) => getIcon(sortOrder),
        },
        {
            key: 'email',
            dataIndex: 'email',
            title: 'Email',
            width: '30%',
            render: (email: string) => <span className={classNames('text-tertiary', styles.email)}>{email}</span>,
            sorter: (data: { email: string; }, data2: { email: string; }) => data.email.localeCompare(data2.email),
            sortIcon: ({ sortOrder }: any) => getIcon(sortOrder),

        },
        {
            key: 'level',
            dataIndex: 'level',
            title: 'Level',
            width: '10%',
            render: (_: any, data: { is_admin: boolean; is_user: boolean; }) => {
                if (data.is_admin) {
                    return <Tag color='green'>General Manager</Tag>;
                }

                if (data.is_user) {
                    return <Tag color='orange'>Local Manager</Tag>;
                }

                if (!data.is_user && !data.is_admin) {
                    return <Tag color='blue'>Worker</Tag>;
                }

                return '';
            },
            sorter: (data: { is_admin: boolean; }, data2: { is_admin: boolean; }) => +data.is_admin - +data2.is_admin,
            sortIcon: ({ sortOrder }: any) => getIcon(sortOrder),
        },
        {
            key: 'assignRegion',
            dataIndex: 'assignRegion',
            title: 'Assign region',

            width: '35%',
            render: (_: any, data: any) => {
                if (Array.isArray(data.regions) && data.regions.length) {
                    const arrOfRegions = data.regions.map((el: any) => {
                        return <Tooltip title={el.name} key={el.uuid}>
                            <Tag className={styles.tag}>{el.name}</Tag>
                        </Tooltip>;
                    });

                    return <div className={classNames(styles.tagsArea, {
                        [styles.tagsAreaOverflow]: arrOfRegions.length >= 3
                    })}>
                        {arrOfRegions}
                        {/* {arrOfRegions.length > 3 && <span className={styles.tagNumber}>+ {arrOfRegions.length - 3}</span>} */}
                    </div>;
                } else {
                    return <span className={classNames('text-tertiary')}>No regions assigned</span>;
                }
            },
        },
        {
            key: 'actions',
            dataIndex: 'actions',
            title: '',
            width: '5%',
            render: (_: any, data: any) => {
                const handleDelete = async () => {
                    try {
                        await deleteUser(data.uuid);
                        setDeleteUserUuid(data.uuid);
                        refetch();
                    } catch (error) {
                        // @ts-ignore
                        console.error('Error deleting user:', error, deleteUserError);
                    }
                };

                return (
                    <div className={classNames(styles.actions)}>
                        <button className={styles.actionBtn} onClick={handleDelete}>
                            <Icons.Delete />
                        </button>
                        <button className={styles.actionBtn}
                            onClick={() => {
                                setAddRegionPopupShow(true);
                                setEditUserUuid(data);
                            }}>
                            <Icons.Edit />
                        </button>
                    </div>
                );
            },
        }
    ];

    return <>
        <HeaderPage
            title='Users'
            subtitle='View your management information across the organisation'>
            <div className={styles.headerGrid}>

                <Button size={ButtonSize.MD}
                    palette={ButtonPalette.SECONDARY_GRAY}
                >
                    <Icons.Download />
                    Generate invite token
                </Button>
                <Button size={ButtonSize.MD}
                    palette={ButtonPalette.PRIMARY}
                    onClick={() => setNewUserPopupShow(true)}
                >
                    <Icons.Plus />
                    Add member
                </Button>
            </div>

        </HeaderPage>
        <BodyPage>
            <div className={styles.tableHeader}>
                <h2 className={classNames('text-primary', 'text-500', 'text-lg')}>Team members {(users && users.length) && <Tag> {users.length} members</Tag>}</h2>

                <Input
                    placeholder='Search user'
                    className={styles.inputSearch}
                    value={inputValue}
                    onChange={(e: any) => {
                        setInputValue(e.target.value);
                    }}
                />
            </div>
            <ConfigProvider
                theme={{
                    components: {
                        Table: tableDesignToken,
                        Pagination: paginationDesignToken
                    },
                    token: designToken,
                }}
            >
                <Table
                    className={styles.header}
                    columns={columns}
                    dataSource={data.filter((el) => el.uuid !== deleteUserUuid).filter((el) => el.username.toLowerCase().includes(inputValue.toLowerCase()))}
                />
            </ConfigProvider>
        </BodyPage>

        {showAddRegionPopup && <AddRegionPopup
            userData={editUserUuid}
            refetchUsers={refetch}
            onClose={() => setAddRegionPopupShow(false)}
        />}
        {showNewUserPopup && <CreateUserPopup
            onClose={() => setNewUserPopupShow(false)}
            refetchUsers={refetch}
        />}

    </>;
};
