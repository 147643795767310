import { Button } from 'components/Button';
import { ROUTES } from 'constants/routes';
import { BodyPage } from 'elements/BodyPage';
import { Breadcrumb } from 'elements/Breadcrumb';
import { HeaderPage } from 'elements/HeaderPage';
import { Skeleton } from 'elements/Skeleton';
import { Icons } from 'icons';
import { CampaignFull } from 'models/Campaigns';
import { Regions } from 'models/Regions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { campaignAPI } from 'services/Campaigns/CampaignsService';
import { regionsAPI } from 'services/Regions/RegionsService';
import { selectCampaign } from 'store/reducers/campaignSlice';
import { closeDrawer } from 'store/reducers/drawerSlice';
import { selectRegion } from 'store/reducers/regionSlice';
import { CampaignRuns } from './CampaignRuns';
import { DigitalMarketingRuns } from './DigitalMarketingRuns';
import { MarketingRuns } from './MarketingRuns';
import styles from './styles.module.scss';

export const RunPage = () => {
    const { uuid, } = useSelector(selectCampaign);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { param1: region_uuid, param2: campaign_uuid } = useParams();

    const regionUuid = useSelector(selectRegion);
    const { data: region = {} as Regions, isLoading: isRegionLoading }: any = regionsAPI.useGetRegionQuery(regionUuid.uuid || region_uuid);

    const { data: campaign = {} as CampaignFull, isLoading: isCampaignLoading, isFetching, refetch } = campaignAPI.useGetCampaignQuery({ campaign_uuid: uuid || campaign_uuid }, {
        skip: !campaign_uuid
    });

    useEffect(() => {
        dispatch(closeDrawer());
    }, []);

    return (<>
        {(isFetching || isCampaignLoading || isRegionLoading) && <Skeleton height={120} />}
        {(isFetching || isCampaignLoading || isRegionLoading) && <Skeleton height={320} />}

        {(!isFetching && !isCampaignLoading && !isRegionLoading) && <>
            <HeaderPage
                title={campaign?.name}
                subtitle='Please select sales for a churn in a campaign run'
                className={styles.headerGrid}
                withBreadcrumb
                breadcrumb={
                    <>
                        <Breadcrumb className={styles.breadcrumb} items={[
                            {
                                title: <Link to={ROUTES.ROOT}><Icons.Home /></Link>,
                            },
                            {
                                title: <Link to={ROUTES.ROOT}>Region</Link>,
                            },
                            {
                                title: <a onClick={() => navigate(-1)}>{region?.name}</a>,
                            },
                            {
                                title: campaign?.name
                            },
                        ]}
                        />
                    </>
                }>
                <div className={styles.headerControls}>
                    <Button onClick={() => refetch()}><Icons.Refresh width={20} height={20} /> Refresh</Button>
                </div>

            </HeaderPage >
            <BodyPage className={styles.pageGrid}>
                <CampaignRuns list={campaign.runs || []} refetch={refetch} />
                <MarketingRuns list={campaign.marketing_runs || []} refetch={refetch} />
                <DigitalMarketingRuns list={campaign.digital_marketing_runs || []} refetch={refetch} />
            </BodyPage>
        </>
        }
    </>);
};;