import { ConfigProvider, Table, Tag } from 'antd';
import { Button } from 'components/Button';
import { UPRNSMap } from 'components/UPRNSMap';
import { BodyPage } from 'elements/BodyPage';
import { EmptyCardNoData } from 'elements/EmptyState';
import { HeaderPage } from 'elements/HeaderPage';
import { Input } from 'elements/Input';
import { Skeleton } from 'elements/Skeleton';
import { ListOfProperties } from 'models/Lead';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { leadsAPI } from 'services/Leads/LeadsService';
import { addPostcode, addUprn, selectLead } from 'store/reducers/leadSlice';
import { addressCreator } from 'utils/addressCreator';
import { designToken, paginationDesignToken, tableDesignToken } from 'utils/designToken';
import { urlFormat } from 'utils/urlFormat';
import styles from './styles.module.scss';

export const TelemarketingPage = () => {
    const dispatch = useDispatch();
    const { postcode: postcodeFromStore = '' } = useSelector(selectLead);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [postcode, setPostcode] = useState<string>('' || postcodeFromStore);
    const { data: listOfProperties = [] as ListOfProperties, isLoading, isFetching } = leadsAPI.useGetAllAddressesByPostcodeQuery({ params: urlFormat({ postcode }) });

    const columns = [
        {
            title: 'Street',
            dataIndex: 'uprn',
            key: 'name',
            render: (_: any, data: any) => {
                return addressCreator(data);
            }
        },
        {
            title: 'Is residential',
            dataIndex: 'is_residential',
            key: 'is_residential',
            render: (is_residential: boolean) => is_residential ? <Tag color='green'>Yes</Tag> : <Tag color='red'>No</Tag>,
        },
        {
            dataIndex: 'uprn',
            title: '',
            render: (uprn: number) => {
                return <Button onClick={() => {
                    dispatch(addUprn({
                        uprn
                    }));

                    navigate(`${pathname}/${uprn}`);
                }}>See census data</Button>;

            },
        },
    ];

    return <>
        <HeaderPage title='Telesales'
        > </HeaderPage>
        <BodyPage className={styles.pageBody}>
            {/* LS15 7Re */}
            {/* NW1 5DB */}
            <Input placeholder='Postcode' label='Postcode' value={postcode} onChange={(e: any) => {
                setPostcode(e.target.value);
                dispatch(
                    addPostcode({
                        postcode: e.target.value
                    })
                );
            }} />

            <div className={styles.body}>
                <UPRNSMap />
                {(isLoading || isFetching) && <Skeleton />}
                {(listOfProperties.length === 0 && !isFetching) && <EmptyCardNoData />}
                {(listOfProperties.length !== 0 && !isFetching) && <ConfigProvider
                    theme={{
                        components: {
                            Table: tableDesignToken,
                            Pagination: paginationDesignToken
                        },
                        token: designToken,
                    }}
                >
                    <Table dataSource={listOfProperties.map((el) => ({ ...el, key: el.uprn }))} columns={columns} />
                </ConfigProvider>}
            </div>
        </BodyPage>

    </>;
};