import { useMap } from 'app/providers/MapProvider';
import { MapWrapper } from 'elements/MapWrapper';
import { useAuth } from 'hooks/useAuth';
import mapboxGl from 'mapbox-gl';
import { Regions } from 'models/Regions';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { regionsAPI } from 'services/Regions/RegionsService';
import { addRegionBounds, selectRegion } from 'store/reducers/regionSlice';
import { fitBoundToSelectedPolygon } from 'utils/fitBoundToSelectedPolygon';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import { LayerVisibility } from './LayerVisibility';
import styles from './Map.module.scss';
import { PropertiesSlider } from './PropertiesSlider';
import { useCityFibrePoints } from './hooks/useCityFibrePoints';
import { useCurrentUserPoints } from './hooks/useCurrentUserPoints';
import { useOpenreach } from './hooks/useOpenreach';
import { usePenModelPolygon } from './hooks/usePenModelPolygon';
import { usePenModelProperties } from './hooks/usePenModelProperties';
import { useStreetWorkPoint } from './hooks/useStreetWorkPoint';
import { useVirginMedia } from './hooks/useVirginMedia';
import { host } from './utils';

(mapboxGl as any).workerClass = MapboxWorker;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
mapboxGl.accessToken = process.env.REACT_APP_MAP_TOKEN!;

export const RegionMap = () => {
    const { param1 } = useParams();
    const { uuid: regionUuid } = useSelector(selectRegion);
    const { data: region = {} as Regions } = regionsAPI.useGetRegionQuery(regionUuid?.uuid || param1);
    const { setDraw, setMap } = useMap();
    const { token } = useAuth();

    const dispatch = useDispatch();

    const mapRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!mapRef.current) return;

        const map = new mapboxGl.Map({
            container: mapRef.current,
            center: [-1.748506, 52.380998],
            transformRequest: (url: any): any => {
                if (url.startsWith(host)) {
                    return {
                        url,
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    };
                }
            },
            style: {
                version: 8,
                sources: {
                    base: {
                        type: 'raster',
                        tiles: ['https://os.fibreplanner.io/z-{z}_x-{x}_y-{y}'],
                        maxzoom: 20,
                        tileSize: 256,
                    },
                },
                layers: [
                    {
                        id: 'base-layer',
                        type: 'raster',
                        source: 'base',
                        minzoom: 0,
                        maxzoom: 24,
                    },
                ],
            },
            bounds: [-3.556744917775376, 50.459380291269525, 1.7584027519666279, 52.42691321915794],
            maxBounds: [-10.731977871337193, 49.59411301175666, 1.9010451201391163, 61.32814923895637],
            zoom: 6,
            minZoom: 5.5 // zoom < 5.5 caused 404s. 
        });

        map.on('load', async () => {
            if (!map.getLayer(`region_${region.uuid}`)) {
                map.addSource(`region_${region.uuid}_source`, {
                    'type': 'geojson',
                    'data': region.geometry
                });

                map.addLayer({
                    'id': `region_${region.uuid}`,
                    'type': 'fill',
                    'source': `region_${region.uuid}_source`,
                    'layout': {},
                    'paint': {
                        // 'fill-color': Colors.Gray,
                        'fill-color': 'transparent',
                        'fill-opacity': 0.2
                    }
                });
            }
            setMap(map);

            const bounds = fitBoundToSelectedPolygon(map, region.uuid);
            dispatch(addRegionBounds({ bounds }));
        });

        return () => {
            setDraw(null);
            setMap(null);
        };
    }, []);

    usePenModelPolygon();
    usePenModelProperties();
    useStreetWorkPoint();
    useCityFibrePoints();
    useOpenreach();
    useVirginMedia();
    useCurrentUserPoints();

    return (
        <>
            <MapWrapper>
                <div className={styles.map} id='map' ref={mapRef} />
                <LayerVisibility />
                <PropertiesSlider />
            </MapWrapper>
        </>
    );
};