import { ConfigProvider, Table, Tag } from 'antd';
import classNames from 'classnames';
import { Dropdown } from 'elements/Dropdown';
import { Icons } from 'icons';
import { MarketingRun } from 'models/Campaigns';
import { designToken, paginationDesignToken, tableDesignToken } from 'utils/designToken';
import { runNameFormatter } from 'utils/runNameFormatter';
import { useDeleteMarketingRun } from '../hooks/useDeleteMarketingRun';
import { RunStatus } from '../types';

export const MarketingRuns = ({ list, refetch }: { list: MarketingRun[]; refetch: any; }) => {
    const runsInCampaign = list.map((el: any) => ({ ...el, key: el.uuid }));

    const { handler } = useDeleteMarketingRun({ refetch });
    const columns = [
        {
            title: 'Name', dataIndex: 'name',
            render: (name: any) => runNameFormatter(name),
        },
        { title: 'Property count', dataIndex: 'max_property_count', },
        {
            title: 'Active', dataIndex: 'is_active', render: (is_active: any) => is_active ? <Tag color='green'>Active</Tag> : <Tag color='red'>Inactive</Tag>,
        },
        {
            title: 'Status', dataIndex: 'status', render: (status: any) => {

                switch (status) {
                    case RunStatus.SUCCESS:
                        return <Tag color='green'>{RunStatus.SUCCESS}</Tag>;
                    case RunStatus.FAILED:
                        return <Tag color='red'>{RunStatus.FAILED}</Tag>;
                    case RunStatus.RUNNING:
                        return <Tag color='yellow'>{RunStatus.RUNNING}</Tag>;
                    case RunStatus.PENDING:
                        return <Tag color='blue'>{RunStatus.PENDING}</Tag>;
                }
            },
        },
        {
            key: 'run_actions',
            title: '',
            dataIndex: 'uuid',
            width: 30,
            render: (_: any, record: { uuid: string; }) => {
                const { uuid } = record;
                console.log(uuid);
                return <Dropdown data={[
                    {
                        content: <><Icons.Delete /> Delete</>,
                        onChange: () => { handler(uuid); }
                    },
                ]} />;
            }
        }
    ];
    return <>
        <h2 className={classNames('text-lg', 'text-500', 'text-primary')}>Marketing runs</h2>
        <ConfigProvider
            theme={{
                components: {
                    Table: tableDesignToken,
                    Pagination: paginationDesignToken
                },
                token: designToken,
            }}
        >
            <Table
                columns={columns}
                pagination={false}
                dataSource={runsInCampaign}
            />
        </ConfigProvider>
    </>;
};