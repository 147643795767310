import { useMap } from 'app/providers/MapProvider';
import mapboxgl from 'mapbox-gl';
import { UserExport } from 'models/HercUsers';
import { useEffect } from 'react';
import { usersAPI } from 'services/Hercules/HerculesUsersService';
import { LayersName, host } from '../utils';

export const useCurrentUserPoints = () => {
    const { map } = useMap();
    const { data: currentUser = {} as UserExport } = usersAPI.useGetCurrentUserQuery();

    const popup = new mapboxgl.Popup({
        closeButton: false,
    });

    useEffect(() => {
        if (!map || !currentUser.company_uuid) return;
        console.log(currentUser);

        if (map.getSource('currentUser')) {
            map.removeLayer(LayersName.CurrentUser);
            map.removeSource('currentUser');
        }

        // set up source
        map.addSource('currentUser', {
            type: 'vector',
            tiles: [
                `${host}/api/v1/companies/${currentUser.company_uuid}/customers/tiles/{z}/{x}/{y}.mvt`,

            ],
            minzoom: 0,
            maxzoom: 15,
        });

        // set up point color 
        map.addLayer({
            id: LayersName.CurrentUser,
            type: 'circle',
            source: 'currentUser',
            'source-layer': 'default',
            paint: {
                'circle-color': '#f79009',
                'circle-opacity': 0.9,
                'circle-radius': {
                    'base': 1.55,
                    'stops': [
                        [12, 2],
                        [22, 90]
                    ]
                },
                // 'circle-radius': 20,
                'circle-stroke-width': 0.5,
                'circle-stroke-color': 'black',
            },
            layout: {
                'visibility': 'none'
            },
            minzoom: 15,
            maxzoom: 24,
        });

        // click on subpolygon
        map.on('click', LayersName.CurrentUser, (e) => {
            // @ts-ignore
            const props = e.features[0].properties!;

            // const company_uuid = props.company_uuid;
            const uprn = props.uprn;
            const is_active = props.is_active;
            const start_date = props.start_date;
            const end_date = props.end_date;
            const external_ref = props.external_ref;
            const external_name = props.external_name;
            const charge = props.charge;
            const classification_code = props?.classification_code;
            const building_number = props?.building_number;
            const postcode = props?.postcode;

            const titleHtml = `<h2><strong>UPRN: ${uprn}</strong></h2>`;
            const propsHtml = `
                <h2>Is active: ${is_active || '-'}</h2>
                <h2>Start date: ${start_date || '-'}</h2>
                <h2>End date: ${end_date || '-'}</h2>
                <h2>External ref: ${external_ref || '-'}</h2>
                <h2>External name: ${external_name || '-'}</h2>
                <h2>Charge: ${charge || '-'}</h2>                    
                <h2>Postcode: <strong>${postcode || '-'}</strong></h2>
                <h2>Classification Code:<strong> ${classification_code}</strong></h2>
                <h2>Building Number: <strong>${building_number || '-'}</strong></h2>
              `;
            const html = `<div class='popup'
                ${titleHtml}
                ${propsHtml}
              </div>`;

            popup.setLngLat(e.lngLat).setHTML(html).addTo(map);

        });

        map.on('mouseenter', LayersName.CurrentUser, () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', LayersName.CurrentUser, () => {
            map.getCanvas().style.cursor = '';
        });
    }, [map, currentUser]);
};