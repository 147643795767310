import classNames from 'classnames';
import styles from '../styles.module.scss';

export const getIcon = (
    sortOrder: string | null) => {
    console.log(sortOrder);
    let asc = null;

    if (!sortOrder) {
        return;
    }

    if (sortOrder === 'ascend') {
        asc = true;
    }

    if (sortOrder === 'descend') {
        asc = false;
    }

    return (
        <svg className={classNames(styles.arrow, { [styles.asc]: asc })} xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" >
            <path d="M4.5 4.5L0 0H9L4.5 4.5Z" fill="var(--text-tertiary)" />
        </svg>);
};