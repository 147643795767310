import { BaseQueryFn, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { readTokenCookie } from 'hooks/useSSO';
import { clearToken } from 'store/reducers/tokenSlice';
import { RootState } from '../store/store';

interface BaseQueryArgs {
    url: string;
    method?: string;
    body?: any;
    headers?: Headers;
    params?: any;
    responseHandler?: (response: Response) => Promise<any>;
    validateStatus?: (response: Response, body: any) => boolean;
}

export const createBaseQueryWithAuth = (baseUrl: string) => {
    const baseQuery = fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const tokenFromCookies = readTokenCookie();
            const tokenFromStore = (getState() as RootState).token.value;

            const token = tokenFromCookies || tokenFromStore;

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    });

    const baseQueryWithAuth: BaseQueryFn<string | BaseQueryArgs, unknown, unknown> = async (
        args,
        api,
        extraOptions,
    ) => {
        const result = await baseQuery(args, api, extraOptions);

        if (result.error && 'status' in result.error && result.error.status === 401) {
            // If a 401 Unauthorized response is received, dispatch the clearToken action
            api.dispatch(clearToken());
        }

        return result;
    };

    return baseQueryWithAuth;
};
