export const getPieData = (data: any) => {
    const dataObj = data;

    const config: { [key: string]: { prefix: string; dataValues: string[] } } = {
        accommodation: {
            prefix: 'accommodation_type_',
            dataValues: [
                'accommodation_type_detached',
                'accommodation_type_semi_detached',
                'accommodation_type_terraced',
                'accommodation_type_purpose_built_block_of_flats_or_tenement',
                'accommodation_type_part_of_converted_or_shared_house',
                'accommodation_type_caravan_or_other_mobile_or_temporary_structure',
            ],
        },
        health: {
            prefix: 'general_health_',
            dataValues: [
                'general_health_very_good_health',
                'general_health_good_health',
                'general_health_fair_health',
                'general_health_bad_health',
                'general_health_very_bad_health',
            ],
        },
        qualifications: {
            prefix: 'highest_qualification_',
            dataValues: [
                'highest_qualification_no_qualifications',
                'highest_qualification_level_1_and_entry_level',
                'highest_qualification_level_2',
                'highest_qualification_apprenticeship',
                'highest_qualification_level_3',
                'highest_qualification_level_4_and_above',
                'highest_qualification_other',
            ],
        },
        economic_activity: {
            prefix: 'economic_activity_',
            dataValues: [
                'economic_activity_active',
                'economic_activity_employee_part_time',
                'economic_activity_employee_full_time',
                'economic_activity_self_employed_part_time_with_employees',
                'economic_activity_self_employed_full_time_with_employees',
                'economic_activity_self_employed_part_time_no_employees',
                'economic_activity_self_employed_full_time_no_employees',
                'economic_activity_unemployed',
                'economic_activity_inactive',
                'economic_activity_inactive_retired',
                'economic_activity_inactive_student',
                'economic_activity_inactive_looking_after_home_or_family',
                'economic_activity_inactive_long_term_sick_or_disabled',
                'economic_activity_inactive_other',
                'economic_activity_student_employee_full_time',
            ],
        },
        household_composition: {
            prefix: 'household_composition_',
            dataValues: [
                'household_composition_one_person',
                'household_composition_one_person_aged_66_and_over',
                'household_composition_one_person_other',
                'household_composition_single_family',
                'household_composition_single_family_all_aged_66_and_over',
                'household_composition_single_family_married_or_civil_couple',
                'household_composition_single_family_married_or_civil_couple_no_children',
                'household_composition_single_family_married_or_civil_couple_dependent_children',
                'household_composition_single_family_married_or_civil_couple_all_children_non_dependen',
                'household_composition_single_family_cohabiting_couple_family',
                'household_composition_single_family_cohabiting_couple_family_no_children',
                'household_composition_single_family_cohabiting_couple_family_with_dependent_children',
                'household_composition_single_family_cohabiting_couple_family_all_children_non_depende',
                'household_composition_single_family_lone_parent_family',
                'household_composition_single_family_lone_parent_family_with_dependent_children',
                'household_composition_single_family_lone_parent_family_all_children_non_dependent',
                'household_composition_other',
                'household_composition_other_with_dependent_children',
                'household_composition_other_including_all_full_time_students_and_all_aged_66_years_an',
            ],
        },
        household_size: {
            prefix: 'household_size_household_size_',
            dataValues: [
                'household_size_household_size_total',
                'household_size_household_size_1',
                'household_size_household_size_2',
                'household_size_household_size_3',
                'household_size_household_size_4',
                'household_size_household_size_5',
                'household_size_household_size_6',
                'household_size_household_size_7',
                'household_size_household_size_8_or_more',
            ],
        },
        occupation: {
            prefix: 'occupation_',
            dataValues: [
                'occupation_total_residents_over_16_in_employment',
                'occupation_managers_directors_and_senior_officials',
                'occupation_professional_occupations',
                'occupation_associate_professional_and_technical_occupations',
                'occupation_administrative_and_secretarial_occupations',
                'occupation_skilled_trades_occupations',
                'occupation_caring_leisure_and_other_service_occupations',
                'occupation_sales_and_customer_service_occupations',
                'occupation_process_plant_and_machine_operatives',
                'occupation_elementary_occupations',
            ],
        },
    };

    const pieData: { [key: string]: any } = {};

    for (const [key, value] of Object.entries(config)) {
        const pieValues = value.dataValues.map(v => {
            return dataObj[v];
        });
        const pieLabels = value.dataValues.map(v => {
            return v
                .split(config[key].prefix)
                .filter(el => el)[0]
                .split('_')
                .join(' ');
        });
        pieData[key] = {
            labels: pieLabels.flat(2),
            values: pieValues,
            textinfo: 'label+name',
        };
    }

    return { pieData };
};
