import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { useState } from 'react';
import { ModalAnalytic } from './ModalAnalytic';

export const FeatureCorrelationAnalysis = () => {
    const [isAnalyticsShown, setAnalyticsShown] = useState(false);
    return <>
        <Button
            size={ButtonSize.MD}
            palette={ButtonPalette.SECONDARY_COLOR}
            onClick={() => setAnalyticsShown(true)}>
            Feature correlation analysis
        </Button>

        {isAnalyticsShown && <ModalAnalytic onClose={() => setAnalyticsShown(false)} />}
    </>;
};