import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Modal } from 'components/Modal';
import { ModalBody } from 'components/Modal/ModalBody';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { PenModelSelect } from 'components/PenModelSelect';
import { Input } from 'elements/Input';
import { Skeleton } from 'elements/Skeleton';
import { NotificationType, useNotification } from 'hooks/useNotification';
import { Icons } from 'icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { campaignAPI } from 'services/Campaigns/CampaignsService';
import { selectCampaign } from 'store/reducers/campaignSlice';
import styles from './styles.module.scss';

interface Props {
    onClose: () => void;
    refetch?: () => any;
}
export const EditCampaignModal: React.FC<Props> = ({ onClose, refetch = () => { } }) => {
    const [updateCampaign, { error: updateCampaignError, isSuccess, isError }] = campaignAPI.useUpdateCampaignMutation();
    const { uuidForEdit: campaign_uuid } = useSelector(selectCampaign);

    const { data: campaign, isLoading: isCampaignLoading } = campaignAPI.useGetCampaignQuery({
        campaign_uuid
    }, {
        refetchOnMountOrArgChange: true
    });

    const [penModelUuid, setPenModelUuid] = useState<string>(campaign?.pen_model_uuid || '');

    const [campaignName, setName] = useState(campaign?.name || '');

    useEffect(() => {
        if (campaign) {
            setPenModelUuid(campaign?.pen_model_uuid);
            setName(campaign?.name);
        }
    }, [isCampaignLoading]);

    const onSubmit = async () => {
        if (!campaignName) return;

        try {
            await updateCampaign({
                campaign_uuid,
                body: {
                    pen_model_uuid: penModelUuid,
                    name: campaignName,
                }
            });
            refetch();
            onClose();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Error creating user:', error, updateCampaignError);
        }
    };

    useNotification({ type: NotificationType.SUCCESS, trigger: isSuccess, text: 'Campaign has been successfully updated!' });
    useNotification({ type: NotificationType.ERROR, trigger: isError, text: 'An error occurred while updating campaign. If the issue persists, contact support for assistance.' });

    return <Modal onClose={() => onClose()}>
        <ModalHeader>
            <div className={styles.decoration}>
                <Icons.Point />
            </div>
            <div className={classNames(styles.textBlock)}>
                <h2 className={classNames('text-lg', 'text-500', 'text-primary')}>Edit Campaign</h2>
                <p className={classNames('text-sm', 'text-400', 'text-tertiary')}>Edit configurations for a campaign in your region</p>
            </div>

        </ModalHeader>
        <ModalBody className={styles.modalGrid}>
            {isCampaignLoading && <Skeleton size={60} />}
            {!isCampaignLoading && <Input
                className={styles.name}
                type='text'
                label='Name'
                placeholder='Add your campaign name'
                value={campaignName}
                onChange={(e: any) => {
                    setName(e.target.value);
                }}
            />}

            <PenModelSelect value={penModelUuid} setValue={setPenModelUuid} />

        </ModalBody>
        <ModalFooter>
            <Button size={ButtonSize.LG}
                palette={ButtonPalette.SECONDARY_GRAY}
                onClick={() => onClose()}
            >
                Discard
            </Button>
            <Button size={ButtonSize.LG}
                palette={ButtonPalette.PRIMARY}
                type='submit'
                onClick={() => onSubmit()}>
                Edit campaign
            </Button>
        </ModalFooter>
    </Modal>;
};