import { Select } from 'elements/Select';
import { Skeleton } from 'elements/Skeleton';
import { usersAPI } from 'services/Hercules/HerculesUsersService';

export const WorkersSelect = ({ value, setValue, isLoading = false }: { value: string; setValue: any; isLoading?: boolean; }) => {
    const { data: users = [], isLoading: isUsersLoading } = usersAPI.useGetAllUsersQuery();

    const options = users.filter((el) => el.is_admin === false && el.is_superuser === false && el.is_user === false).map((el: any) => {
        return {
            value: el.uuid,
            label: el.username
        };
    });

    return <>
        {isUsersLoading && <Skeleton size={60} />}
        {!isUsersLoading && <Select
            // isBlock
            label='Worker'
            data={options}

            value={value}
            width={{
                width: '100%',
                maxWidth: '100%'
            }}
            antdProps={{
                loading: isLoading
            }}
            onChange={(value) => {
                setValue(value);
            }} />}
    </>;
};