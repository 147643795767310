import { Theme, useTheme } from 'app/providers/ThemeProvider';
import classNames from 'classnames';
import { DarkThemeIcon } from './icons/DarkThemeIcon';
import { LightThemeIcon } from './icons/LightThemeIcon';
import style from './style.module.scss';

export const ThemeSwitcher = () => {
    const { theme, toggleTheme } = useTheme();

    return (<>
        <div className={style.themeContainer} data-testid='theme-switcher'>
            <input
                className={style.themeInput}
                type='checkbox'
                onChange={toggleTheme}
            />
            <div className={classNames(style.themeItem, {
                [style.themeItemActive]: theme === Theme.LIGHT
            })}>
                <LightThemeIcon />
            </div>
            <div className={classNames(style.themeItem, {
                [style.themeItemActive]: theme !== Theme.LIGHT
            })}>
                <DarkThemeIcon />
            </div>
        </div>
    </>);
};