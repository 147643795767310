import classNames from 'classnames';
import { DemographicBreakdown } from './DemographicBreakdown';
import { FeatureCorrelationAnalysis } from './FeatureCorrelationAnalysis';
import styles from './styles.module.scss';

export const AnalyticsSection = () => {
    return (<>
        <h2 className={classNames('text-primary', 'text-lg', 'text-500', styles.subtitle)}>Analytics</h2>

        <div className={styles.controls}>
            <DemographicBreakdown />
            <FeatureCorrelationAnalysis />
        </div>
    </>);
}; 