import { useMap } from 'app/providers/MapProvider';
import mapboxgl from 'mapbox-gl';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCampaign } from 'store/reducers/campaignSlice';
import { Colors, LayersName, host } from '../utils';

export const useStreetWorkPoint = () => {
    const { map } = useMap();
    const { uuidForMap: campaignId } = useSelector(selectCampaign);

    const popup = new mapboxgl.Popup({
        closeButton: false,
    });

    useEffect(() => {
        if (!map || !campaignId) return;

        if (map.getSource('streetworks')) {
            map.removeLayer(LayersName.Streetworks);
            map.removeSource('streetworks');
        }

        // set up source
        map.addSource('streetworks', {
            type: 'vector',
            tiles: [
                `${host}/api/v1/campaigns/${campaignId}/streetworks/tiles/{z}/{x}/{y}.mvt`,
            ],
            minzoom: 15,
            maxzoom: 24,
        });

        // set up point color for poly
        map.addLayer({
            id: LayersName.Streetworks,
            type: 'circle',
            source: 'streetworks',
            'source-layer': 'default',
            paint: {
                'circle-color': Colors.Red,
                'circle-opacity': 0.7,
                'circle-radius': {
                    'base': 1.5,
                    'stops': [
                        [12, 2],
                        [22, 90]
                    ]
                },
                'circle-stroke-width': 0.5,
                'circle-stroke-color': 'black'
            },
            layout: {
                'visibility': 'none'
            },
            minzoom: 15,
            maxzoom: 24,
        });

        // click on subpolygon
        map.on('click', LayersName.Streetworks, (e) => {
            // @ts-ignore
            const props = e.features[0].properties;

            const id = props?.id;
            const promoter = props?.promoter;
            const active_works = props?.active_works;
            const works_desc = props?.works_desc;
            const distance = props?.distance;

            const titleHtml = `<h1>ID: <strong>${id}</strong></h1>`;
            const propsHtml = `
                <h2>Promoter: <strong>${promoter}</strong></h2>
                <h2>Is work still active? <strong>${active_works ? 'Yes' : 'No'}</strong></h2>
                <h2>Description: <strong>${works_desc}</strong></h2>
                <h2>Distance: <strong>${distance}</strong></h2>
            `;
            const html = `<div class='popup'>
                ${titleHtml}
                ${propsHtml}
            </div>`;

            popup.setLngLat(e.lngLat).setHTML(html).addTo(map);

        });

        map.on('mouseenter', LayersName.Streetworks, () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', LayersName.Streetworks, () => {
            map.getCanvas().style.cursor = '';
        });
    }, [map, campaignId]);
};