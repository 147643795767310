import { ConfigProvider, Slider as SliderAntd } from 'antd';
import React from 'react';
import { designToken, sliderDesignToken } from 'utils/designToken';
// import { sliderDesignToken } from './utils/designToken';

interface Props {
    value?: any;
    max?: number;
    min?: number;
    onChange?: any;
    isRange?: boolean;
    step?: any;
}

export const Slider: React.FC<Props> = ({ step, value, min, max, onChange, isRange }) => {
    return (
        <ConfigProvider
            theme={{
                components: {
                    Slider: sliderDesignToken
                },
                token: designToken,
            }}
        >
            <SliderAntd
                range={isRange}
                defaultValue={value}
                value={value}
                step={step}
                max={max}
                min={min}
                onChange={onChange} />
        </ConfigProvider>

    );
};