import classNames from 'classnames';
import { Modal } from 'components/Modal';
import { ModalBody } from 'components/Modal/ModalBody';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { Chart } from 'elements/Chart';
import { Skeleton } from 'elements/Skeleton';
import { ModelCorrelation } from 'models/PenModel';
import { penModelAPI } from 'services/PenModel';
import styles from '../styles.module.scss';

export const ModalAnalytic = ({ onClose }: any) => {

    const { data: correlation = {} as ModelCorrelation, isLoading } = penModelAPI.useGetStaticModelCorrelationsQuery();
    console.log(correlation);

    const dataArr = [];

    for (const key in correlation) {
        dataArr.push({
            type: 'bar',
            // @ts-ignore
            x: [correlation[key]],
            y: [key],
            marker: {
                // @ts-ignore
                color: correlation[key] >= 0 ? 'green' : 'red'
            },
            name: key,
            orientation: 'h',
            transforms: [{
                type: 'sort',
                target: 'x',
                order: 'descending'
            }]
        });
    }

    return <Modal onClose={() => onClose()}>
        <ModalHeader isSticky>
            <div>
                <h2 className={classNames('text-lg', 'text-500', 'text-primary')}>Feature correlation analysis</h2>
            </div>
        </ModalHeader>
        <ModalBody className={classNames(styles.bodyGrid, styles.body, styles.bodyHeight)}>
            {isLoading && <Skeleton />}
            <Chart plotData={dataArr.sort((a, b) => Math.abs(a.x[0]) - Math.abs(b.x[0]))} layout={{
                showlegend: false,
                margin: {
                    l: 550,
                    r: 20,
                    t: 20,
                    b: 20
                },
            }}
                style={{
                    minHeight: '1600px'
                }
                } />
        </ModalBody>
    </Modal>;
};