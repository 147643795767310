/**
 * Formats an object into a URL query string, encoding keys and values appropriately.
 *
 * If a key has an array as its value, each element of the array will be added as a separate key-value pair in the query string.
 *
 * @param {Record<string, any>} data - The object containing key-value pairs to be formatted into a query string.
 * @returns {string} The formatted URL query string.
 *
 * @example
 * const params = {
 *     search: 'example',
 *     tags: ['tag1', 'tag2'],
 *     sort: 'asc'
 * };
 * const queryString = urlFormat(params);
 * console.log(queryString); // Output: "search=example&tags=tag1&tags=tag2&sort=asc"
 */

export const urlFormat = (data: any) => {
    const urlParams = [];

    for (const key in data) {
        if (Array.isArray(data[key])) {
            urlParams.push(...data[key].map((value: any) => `${key}=${value}`));
        } else {
            urlParams.push(`${key}=${data[key]}`);
        }
    }

    return urlParams.join('&');
};