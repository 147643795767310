import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Modal } from 'components/Modal';
import { ModalBody } from 'components/Modal/ModalBody';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { PenModelSelect } from 'components/PenModelSelect';
import { Input } from 'elements/Input';
import { NotificationType, useNotification } from 'hooks/useNotification';
import { Icons } from 'icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { campaignAPI } from 'services/Campaigns/CampaignsService';
import { regionsAPI } from 'services/Regions/RegionsService';
import { selectRegion } from 'store/reducers/regionSlice';
import { CreateRegionInputs, defaultValuesCampaign, validationSchema } from '../FormValidation';
import styles from './styles.module.scss';

interface Props {
    onClose: () => void;
}

export const AddCampaignModal: React.FC<Props> = ({ onClose }) => {
    const { param1 } = useParams();
    const [createCampaign, { error: createCampaignError, isSuccess, isError }] = campaignAPI.useCreateCampaignMutation();
    const regionUuid = useSelector(selectRegion);

    const { refetch }: any = regionsAPI.useGetRegionQuery(regionUuid.uuid || param1);

    const [penModelUuid, setPenModelUuid] = useState<string>();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: defaultValuesCampaign,
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (data: typeof defaultValuesCampaign) => {
        try {
            await createCampaign({
                region_uuid: regionUuid.uuid || param1,
                pen_model_uuid: penModelUuid,
                name: data.name,
            });
            refetch();
            onClose();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Error creating campaign:', error, createCampaignError);
        }
    };

    useNotification({ type: NotificationType.SUCCESS, trigger: isSuccess, text: 'New campaign has been successfully created!' });
    useNotification({ type: NotificationType.ERROR, trigger: isError, text: 'An error occurred while creating campaign. If the issue persists, contact support for assistance.' });

    watch(CreateRegionInputs.NAME);

    return <Modal onClose={() => onClose()}>
        <ModalHeader>
            <div className={styles.decoration}>
                <Icons.Point />
            </div>
            <div className={classNames(styles.textBlock)}>
                <h2 className={classNames('text-lg', 'text-500', 'text-primary')}>Add Campaign</h2>
                <p className={classNames('text-sm', 'text-400', 'text-tertiary')}>Set configurations for a new campaign in your region</p>
            </div>

        </ModalHeader>
        <ModalBody className={styles.modalGrid}>
            <Input
                className={styles.name}
                type='text'
                label='Name*'
                placeholder='Add your campaign name'
                error={{
                    message: errors.name?.message
                }}
                registerProps={
                    { ...register(CreateRegionInputs.NAME, { required: true }) }
                }
            />

            <PenModelSelect value={penModelUuid} setValue={setPenModelUuid} />
        </ModalBody>
        <ModalFooter>
            <Button size={ButtonSize.LG}
                palette={ButtonPalette.SECONDARY_GRAY}
                onClick={() => onClose()}
            >
                Discard
            </Button>
            <Button size={ButtonSize.LG}
                palette={ButtonPalette.PRIMARY}
                type='submit'
                onClick={handleSubmit(onSubmit)}>
                Add campaign
            </Button>
        </ModalFooter>
    </Modal>;
};