/**
 * Formats a given name string by splitting it based on underscores and appending a formatted date derived from the last segment.
 *
 * The last segment is expected to be a timestamp in milliseconds since the epoch. If the name contains no underscores, it returns the original name.
 *
 * @param {string} name - The name string to be formatted.
 * @returns {string} The formatted name string with the date appended, or the original name if no formatting is applicable.
 *
 * @example
 * const formattedName = runNameFormatter('report_20230101_1672531199000');
 * console.log(formattedName); // Output: "report_20230101 - 01.01.2023"
 */

export const runNameFormatter = (name: string) => {
    const nameSplit = name.split('_');

    if (nameSplit.length === 1) {
        return name;
    } else {
        const timeStamp = new Date(+nameSplit[nameSplit.length - 1]);
        nameSplit.pop();
        const date = `${nameSplit.join('_')} - ${timeStamp.getDate() <= 9 ? '0' + timeStamp.getDate() : timeStamp.getDate()}.${timeStamp.getMonth() + 1 <= 9 ? '0' + (timeStamp.getMonth() + 1) : (timeStamp.getMonth() + 1)}.${timeStamp.getFullYear()}`;

        return date;
    }
};