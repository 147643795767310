import { createApi } from '@reduxjs/toolkit/query/react';
import { CampaignFull, CampaignUpdate, Campaigns, CampaignsExport, CampaignsFullExport, CampaignsPropertiesExport } from 'models/Campaigns';
import { createBaseQueryWithAuth } from '../createBaseQueryWithAuth';

export const campaignAPI = createApi({
    reducerPath: 'campaignAPI',
    baseQuery: createBaseQueryWithAuth(`${process.env.REACT_APP_HERMES_API_URL}/api/v1`),
    tagTypes: ['Create', 'Delete', 'Update'],
    endpoints: (build) => ({
        createCampaign: build.mutation<CampaignsExport, Campaigns>({
            query: (body) => ({
                url: '/campaigns',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Create']
        }),
        updateCampaign: build.mutation<CampaignsExport, CampaignUpdate>({
            query: ({ body, campaign_uuid }) => ({
                url: `/campaigns/${campaign_uuid}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['Update']
        }),

        getCampaign: build.query<CampaignFull, { campaign_uuid: string; }>({
            query: ({ campaign_uuid }) => ({
                url: `campaigns/${campaign_uuid}?embed=runs&embed=marketing_runs&embed=digital_marketing_runs`,
                method: 'GET',
            }),
        }),

        getAllCampaigns: build.query<CampaignsFullExport, void>({
            query: () => ({
                url: '/campaigns',
                method: 'GET',
            }),
        }),
        deleteCampaign: build.mutation<CampaignsExport, { campaign_uuid: string; }>({
            query: ({ campaign_uuid }) => ({
                url: `/campaigns/${campaign_uuid}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Delete']
        }),
        getSalesStats: build.query<any, { campaign_uuid: string, params?: any; }>({
            query: ({ campaign_uuid, params }) => ({
                url: `/campaigns/${campaign_uuid}/stats/sales?${params}`,
                method: 'GET',
            }),
        }),

        getChurnStats: build.query<any, { campaign_uuid: string, params?: any; }>({
            query: ({ campaign_uuid, params }) => ({
                url: `/campaigns/${campaign_uuid}/stats/churn?${params}`,
                method: 'GET',
            }),
        }),
        getDoorKnockedStats: build.query<any, { campaign_uuid: string, params?: any; }>({
            query: ({ campaign_uuid, params }) => ({
                url: `/campaigns/${campaign_uuid}/stats/doors-knocked?${params}`,
                method: 'GET',
            }),
        }),
        getProperties: build.query<CampaignsPropertiesExport, { campaign_uuid: string, params?: any; }>({
            query: ({ campaign_uuid, params }) => ({
                url: params ? `campaigns/${campaign_uuid}/properties?${params}` : `campaigns/${campaign_uuid}/properties`,
                method: 'GET',
            }),
        }),
        getCountOfProperties: build.query<{ count: number; }, { campaign_uuid: string, params?: string; }>({
            query: ({ campaign_uuid, params }) => ({
                url: `campaigns/${campaign_uuid}/properties/count?${params}`,
                method: 'GET',
            }),
        }),
    })
});