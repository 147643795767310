import classNames from 'classnames';
import { ROUTES } from 'constants/routes';
import { BodyPage } from 'elements/BodyPage';
import { Breadcrumb } from 'elements/Breadcrumb';
import { HeaderPage } from 'elements/HeaderPage';
import { PieChart } from 'elements/PieChart/PieChart';
import { Skeleton } from 'elements/Skeleton';
import { Icons } from 'icons';
import { PropertyWithCensus } from 'models/Lead';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { leadsAPI } from 'services/Leads/LeadsService';
import { selectLead } from 'store/reducers/leadSlice';
import { addressCreator } from 'utils/addressCreator';
import { getPieData } from 'utils/getPieData';
import styles from './styles.module.scss';

export const CensusPage = () => {
    const { uprn } = useSelector(selectLead);

    const { data: propertyData = {} as PropertyWithCensus, isLoading } = leadsAPI.useGetPropertyDetailsByUPRNQuery({ uprn });

    console.log(propertyData);
    if (isLoading) {
        return <Skeleton />;
    }
    const { pieData: chartsData = {} }: any = getPieData(propertyData.census);

    return <>
        <HeaderPage
            title={addressCreator(propertyData)}
            withBreadcrumb
            className={styles.headerGrid}

            breadcrumb={
                <Breadcrumb items={[
                    {
                        title: <Link to={ROUTES.ROOT}><Icons.Home /></Link>,
                    },
                    {
                        title: <Link to={ROUTES.TELEMARKETING}>Telesales</Link>,
                    },
                    {
                        title: addressCreator(propertyData),
                    },
                ]}
                />}
        > </HeaderPage>
        <BodyPage className={styles.pageContainer}>

            {chartsData?.accommodation && <div className={styles.chartContainer}>
                <h2 className={classNames('display-xs', 'text-primary', 'text-center')}>Accommodation type</h2>
                <PieChart data={chartsData?.accommodation} />
            </div>}
            {chartsData?.health && <div className={styles.chartContainer}>
                <h2 className={classNames('display-xs', 'text-primary', 'text-center')}>Health</h2>
                <PieChart data={chartsData?.health} />
            </div>}
            {chartsData?.qualifications && <div className={styles.chartContainer}>
                <h2 className={classNames('display-xs', 'text-primary', 'text-center')}>Qualifications</h2>
                <PieChart data={chartsData?.qualifications} />
            </div>}
            {chartsData?.economic_activity && <div className={styles.chartContainer}>
                <h2 className={classNames('display-xs', 'text-primary', 'text-center')}>Economic Activity</h2>
                <PieChart data={chartsData?.economic_activity} />
            </div>}
            {chartsData?.household_composition && <div className={styles.chartContainer}>
                <h2 className={classNames('display-xs', 'text-primary', 'text-center')}>Household Composition</h2>
                <PieChart data={chartsData?.household_composition} />
            </div>}
            {chartsData?.household_size && <div className={styles.chartContainer}>
                <h2 className={classNames('display-xs', 'text-primary', 'text-center')}>Household</h2>
                <PieChart data={chartsData?.household_size} />
            </div>}
            {chartsData?.occupation && <div className={styles.chartContainer}>
                <h2 className={classNames('display-xs', 'text-primary', 'text-center')}>Occupation</h2>
                <PieChart data={chartsData?.occupation} />
            </div>}
        </BodyPage>

    </>;
};