import { createSlice } from '@reduxjs/toolkit';

const campaignSlice = createSlice({
    name: 'campaign',
    initialState: {
        uuid: null,
        uuidForMap: null,
        uuidForEdit: null,
        uuidForExport: null
    },
    reducers: {
        addUuid: (state, action) => {
            state.uuid = action.payload.uuid;
        },
        addUuidForMap: (state, action) => {
            state.uuidForMap = action.payload.uuid;
        },
        addUuidForExport: (state, action) => {
            state.uuidForExport = action.payload.uuid;
        },
        addUuidForEdit: (state, action) => {
            state.uuidForEdit = action.payload.uuid;
        },
        deleteUuidForMap: (state) => {
            state.uuidForMap = null;
        },
        deleteUuid: (state) => {
            state.uuidForMap = null;
        },
        deleteUuidForExport: (state) => {
            state.uuidForExport = null;
        },
        deleteUuidForEdit: (state) => {
            state.uuidForEdit = null;
        },
        deleteAllCampaignUuid: (state) => {
            state.uuidForEdit = null;
            state.uuidForExport = null;
            state.uuidForMap = null;
        },
    }
});

export const selectCampaign = (state: any) => state.campaign;

export const {
    addUuidForMap,
    addUuidForExport,
    addUuidForEdit,
    deleteUuidForMap,
    deleteUuidForExport,
    deleteUuidForEdit,
    deleteAllCampaignUuid,
    addUuid,
    deleteUuid
} = campaignSlice.actions;

export default campaignSlice.reducer;
