/**
 * Formats an address by concatenating different address components into a single string.
 *
 * @param {object} data - The object containing address components.
 * @param {string} [data.sub_building_name] - The name of the sub-building (if applicable).
 * @param {string} [data.building_name] - The name of the building (if applicable).
 * @param {string} [data.building_number] - The number of the building.
 * @param {string} [data.dependent_road] - The dependent road name (if applicable).
 * @param {string} [data.road] - The road name.
 * @param {string} [data.dependent_locality] - The dependent locality (if applicable).
 * @param {string} [data.locality] - The locality or village name.
 * @param {string} [data.town] - The town or city name.
 * 
 * @returns {string} The formatted address string.
 * 
 * @example
 * const addressData = {
 *   sub_building_name: 'Apartment 5A',
 *   building_name: 'Sunrise Towers',
 *   building_number: '12',
 *   road: 'Main Street',
 *   locality: 'West End',
 *   town: 'Springfield'
 * };
 * 
 * const address = addressCreator(addressData);
 * console.log(address); // Output: "Apartment 5A, Sunrise Towers, 12, Main Street, West End, Springfield"
 */

export const addressCreator = (data: any) => {
    const sub_building_name = data.sub_building_name;
    const building_name = data.building_name;
    const building_number = data.building_number;
    const dependent_road = data.dependent_road;
    const road = data.road;
    const dependent_locality = data.dependent_locality;
    const locality = data.locality;
    const town = data.town;

    return [sub_building_name, building_name, building_number, dependent_road, road, dependent_locality, locality, town].filter((el) => el).join(', ');
};