import { ConfigProvider, Radio } from 'antd';
import classNames from 'classnames';
import { Drawer } from 'elements/Drawer';
import { CampaignsFullExport } from 'models/Campaigns';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { campaignAPI } from 'services/Campaigns/CampaignsService';
import { selectCampaign } from 'store/reducers/campaignSlice';
import { KnockListForm } from '../Forms/KnockListForm';
import { MarketingForm } from '../Forms/MarketingForm';
import styles from './styles.module.scss';

enum TypeOfExport {
    MARKETING = 'marketing',
    DIGITAL_MARKETING = 'digital_marketing',
    DOOR_TO_DOOR = 'door_to_door'
}

export const ExportDrawer = () => {
    const { data: campaigns = [] as CampaignsFullExport } = campaignAPI.useGetAllCampaignsQuery();
    const { uuidForExport: uuid } = useSelector(selectCampaign);
    const campaign = campaigns.find((el) => el.uuid === uuid);

    const [exportType, setExportType] = useState(TypeOfExport.DOOR_TO_DOOR);

    const onExportTypeChange = (e: any) => {
        setExportType(e.target.value);
    };

    return <Drawer title={`Export for ${campaign?.name}`} subtitle='Email the export of properties for a campaign'>
        <div className={styles.grid}>
            <p className={classNames('text-sm', 'text-500', 'text-secondary', styles.title)}> Select type of export:</p>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryHover: 'rgb(var(--brand-400))',
                        colorBgContainer: 'rgb(var(--white))',
                        controlOutline: 'rgb(var(--brand-500))',
                        controlOutlineWidth: 2,
                    },
                }}
            >
                <Radio.Group onChange={onExportTypeChange} value={exportType} className={classNames('text-secondary')}>
                    <Radio value={TypeOfExport.DOOR_TO_DOOR} className={classNames('text-secondary')}>Door to Door</Radio>
                    <Radio value={TypeOfExport.MARKETING} className={classNames('text-secondary')}>Marketing</Radio>
                </Radio.Group>
            </ConfigProvider>
        </div>

        {exportType === TypeOfExport.DOOR_TO_DOOR && <KnockListForm />}
        {exportType === TypeOfExport.MARKETING && <MarketingForm />}
    </Drawer>;
};