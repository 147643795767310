export const designToken = {
    colorBgContainer: 'var(--bg-primary)',
    colorText: 'var(--text-primary)',
    colorTextDescription: 'var(--text-primary)',
    colorPrimaryHover: 'rgb(var(--brand-400))',
    controlOutline: 'rgb(var(--brand-500))',
    controlOutlineWidth: 2,
};

export const sliderDesignToken = {
    trackBg: 'rgb(var(--brand-400))',
    railBg: 'var(--border-primary)',
    railHoverBg: 'var(--border-primary)',
    trackHoverBg: 'rgb(var(--brand-400))',
    handleActiveColor: 'rgb(var(--brand-600))',
    handleColor: 'rgb(var(--brand-500))',
};

export const tableDesignToken = {
    borderColor: 'var(--border-secondary)',
    expandIconBg: 'var(--bg-primary)',
    headerBg: 'var(--bg-primary)',
    headerBorderRadius: 12,
    headerColor: 'var(--text-tertiary)',
    rowHoverBg: 'var(--bg-primary_hover)',
    headerSplitColor: 'transparent',
    headerSortActiveBg: 'var(--bg-secondary)',
    headerSortHoverBg: 'var(--bg-secondary)',
    bodySortBg: 'var(--bg-secondary)'
};

export const paginationDesignToken = {
    itemActiveBg: 'var(--bg-primary)',
    itemBg: 'green',
    itemLinkBg: 'yellow',
    colorPrimary: 'var(--text-primary)'
};

export const selectDesignToken = {
    optionSelectedBg: 'var(--bg-secondary)',
    selectorBg: 'var(--bg-primary)',
    multipleItemBg: 'var(--bg-secondary)',
    optionSelectedColor: 'var(--text-primary)',
};

export const datePickerDesignToken = {
    cellActiveWithRangeBg: 'var(--bg-tertiary)',
};

export const cascaderDesignToken = {
    optionSelectedBg: 'var(--bg-secondary)',
};