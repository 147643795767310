import { useEffect } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * useNotification hook
 *
 * The useNotification hook is a custom React hook that leverages react-toastify to display toast notifications
 * 
 * @param {NotificationType} type - Specifies the type of notification.
 * @param {boolean} trigger - Determines when the toast notification should be triggered
 * @param {string} text - Optional, The message that will appear in the notification toast
 *
 * @example
 *
 * useNotification({
        type: NotificationType.SUCCESS,
        trigger: triggerNotification,
        text: 'Data saved successfully!',
    });
*/

export enum NotificationType {
    SUCCESS = 'success',
    ERROR = 'error',
}

interface Props {
    type: NotificationType;
    trigger: boolean;
    text?: string;
}

export const useNotification = ({
    type = NotificationType.SUCCESS,
    trigger = true,
    text = 'Action completed!',
}: Props) => {
    useEffect(() => {
        if (trigger) {
            toast[type](text, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [trigger]);
};
