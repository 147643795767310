import { createApi } from '@reduxjs/toolkit/query/react';
import { CampaignsExport } from 'models/Campaigns';
import { createBaseQueryWithAuth } from '../createBaseQueryWithAuth';

export const campaignDigitalMarketingRunAPI = createApi({
    reducerPath: 'campaignDigitalMarketingRun',
    baseQuery: createBaseQueryWithAuth(`${process.env.REACT_APP_HERMES_API_URL}/api/v1`),
    tagTypes: ['Create', 'Delete', 'Update'],
    endpoints: (build) => ({
        getAllDigitalMarketingRuns: build.query<any, void>({
            query: () => ({
                url: '/campaign-digital-marketing-runs',
                method: 'GET',
            }),
        }),
        createCampaignDigitalMarketingRunExport: build.mutation<CampaignsExport, {
            body: {
                campaign_uuid: string;
                max_property_count: number;
                name: string;
            };
        }>({
            query: ({ body }) => ({
                url: '/campaign-digital-marketing-runs',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Create']
        }),
        deleteRun: build.mutation<{
            detail: string,
            deleted: 0;
        }, {
            digital_marketing_run_uuid: string;
        }>({
            query: ({ digital_marketing_run_uuid, }) => ({
                url: `/campaign-digital-marketing-runs/${digital_marketing_run_uuid}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Delete']
        }),

    })
});