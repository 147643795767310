import classNames from 'classnames';
import { Error } from 'components/Error';
import { RegionMap } from 'components/RegionMap';
import { ROUTES } from 'constants/routes';
import { BodyPage } from 'elements/BodyPage';
import { Breadcrumb } from 'elements/Breadcrumb';
import { EmptyCardNoData } from 'elements/EmptyState';
import { HeaderPage } from 'elements/HeaderPage';
import { Skeleton } from 'elements/Skeleton';
import { Icons } from 'icons';
import { Regions } from 'models/Regions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { regionsAPI } from 'services/Regions/RegionsService';
import { deleteUuidForEdit, selectCampaign } from 'store/reducers/campaignSlice';
import { closeDrawer, selectDrawer } from 'store/reducers/drawerSlice';
import { selectRegion } from 'store/reducers/regionSlice';
import { AnalyticsSection } from './AnalyticsSection';
import { CampaignSection } from './CampaignSection';
import { ExportDrawer } from './ExportDrawer';
import { EditCampaignModal } from './Forms/EditCampaignModal';
import { HeaderControls } from './HeaderControls';
import styles from './styles.module.scss';

const HeaderBreadcrumb = ({ regionName }: { regionName: string; }) => {
    return <Breadcrumb className={styles.breadcrumb} items={[
        {
            title: <Link to={ROUTES.ROOT}><Icons.Home /></Link>,
        },
        {
            title: <Link to={ROUTES.ROOT}>Region</Link>,
        },
        {
            title: regionName,
        },
    ]}
    />;
};

export const CampaignPage = () => {
    // const [firstCampaign, setFirstCampaign] = useState('');
    const { param1 } = useParams();
    const regionUuid = useSelector(selectRegion);
    const { data: region = {} as Regions, isLoading, error, refetch } = regionsAPI.useGetRegionQuery(regionUuid.uuid || param1);
    const drawerState = useSelector(selectDrawer);
    const dispatch = useDispatch();
    const { uuidForEdit } = useSelector(selectCampaign);

    useEffect(() => {
        dispatch(closeDrawer());
        // dispatch(deleteAllCampaignUuid());
    }, []);

    if (error) {
        return <Error />;
    }

    if (isLoading) {
        return <Skeleton />;
    }

    return <>
        <HeaderPage
            title={region.name}
            subtitle='Please select a campaign'
            className={styles.headerGrid}
            withBreadcrumb
            breadcrumb={
                <HeaderBreadcrumb regionName={region.name} />
            }>
            <HeaderControls />
        </HeaderPage>
        <BodyPage className={classNames(styles.bodyGrid, {
            [styles.drawerOpen]: drawerState.isOpen
        })}>

            {!isLoading && <RegionMap />}

            {!isLoading && region.campaigns?.length !== 0 && <div className={styles.pageScroll}>
                <AnalyticsSection />
                <CampaignSection />
            </div>}

            {!isLoading && region.campaigns?.length === 0 && <EmptyCardNoData message='No entries found' />}
        </BodyPage>
        {uuidForEdit && <EditCampaignModal onClose={() => dispatch(deleteUuidForEdit())} refetch={refetch} />}
        <ExportDrawer />
    </>;
};