import classNames from 'classnames';
import { Modal } from 'components/Modal';
import { ModalBody } from 'components/Modal/ModalBody';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { PieChart } from 'elements/PieChart/PieChart';
import { Skeleton } from 'elements/Skeleton';
import { PropertyWithCensus } from 'models/Lead';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { regionsAPI } from 'services/Regions/RegionsService';
import { selectRegion } from 'store/reducers/regionSlice';
import { getPieData } from 'utils/getPieData';
import styles from '../styles.module.scss';

export const ModalAnalytic = ({ onClose }: any) => {
    const regionUuid = useSelector(selectRegion);
    const { param1 } = useParams();

    const { data: propertyData = {} as PropertyWithCensus, isLoading } = regionsAPI.useGetDemographicsQuery({ region_uuid: regionUuid.uuid || param1 });
    const { pieData: chartsData = {} }: any = propertyData.census ? getPieData(propertyData.census) : {};

    return <Modal onClose={() => onClose()}>
        <ModalHeader isSticky>
            <div>
                <h2 className={classNames('text-lg', 'text-500', 'text-primary')}>Demographic breakdown</h2>
            </div>
        </ModalHeader>
        <ModalBody className={styles.body}>
            {isLoading && <Skeleton />}
            {(chartsData?.accommodation && !isLoading) && <div className={styles.chartContainer}>
                <h2 className={classNames('display-xs', 'text-primary', 'text-center')}>Accommodation type</h2>
                <PieChart data={chartsData?.accommodation} />
            </div>}
            {(chartsData?.health && !isLoading) && <div className={styles.chartContainer}>
                <h2 className={classNames('display-xs', 'text-primary', 'text-center')}>Health</h2>
                <PieChart data={chartsData?.health} />
            </div>}
            {(chartsData?.qualifications && !isLoading) && <div className={styles.chartContainer}>
                <h2 className={classNames('display-xs', 'text-primary', 'text-center')}>Qualifications</h2>
                <PieChart data={chartsData?.qualifications} />
            </div>}
            {(chartsData?.economic_activity && !isLoading) && <div className={styles.chartContainer}>
                <h2 className={classNames('display-xs', 'text-primary', 'text-center')}>Economic Activity</h2>
                <PieChart data={chartsData?.economic_activity} />
            </div>}
            {(chartsData?.household_composition && !isLoading) && <div className={styles.chartContainer}>
                <h2 className={classNames('display-xs', 'text-primary', 'text-center')}>Household Composition</h2>
                <PieChart data={chartsData?.household_composition} />
            </div>}
            {(chartsData?.household_size && !isLoading) && <div className={styles.chartContainer}>
                <h2 className={classNames('display-xs', 'text-primary', 'text-center')}>Household</h2>
                <PieChart data={chartsData?.household_size} />
            </div>}
            {(chartsData?.occupation && !isLoading) && <div className={styles.chartContainer}>
                <h2 className={classNames('display-xs', 'text-primary', 'text-center')}>Occupation</h2>
                <PieChart data={chartsData?.occupation} />
            </div>}
        </ModalBody>
    </Modal>;
};