import { Select } from 'elements/Select';
import { Skeleton } from 'elements/Skeleton';
import { PenModel } from 'models/PenModel';
import { penModelAPI } from 'services/PenModel';

export const PenModelSelect = ({ value, setValue }: { value: string | void; setValue: any; }) => {
    const { data: penModels = [], isLoading } = penModelAPI.useGetAllPenModelsQuery();

    const options = penModels.map((el: PenModel) => {
        return {
            value: el.uuid,
            label: el.name
        };
    });

    return <>
        {isLoading && <Skeleton size={60} />}
        {!isLoading && <Select
            isBlock
            label='Pen model'
            placeholder='Select'
            data={options}
            value={value}
            width={{
                width: '100%',
                maxWidth: '100%'
            }}
            onChange={(value) => {
                setValue(value);
            }} />}
    </>;
};