export const chunkColours: { [key: string]: string; } = {
    Worker_0: '#FF44CC', //   Vibrant Magenta
    Worker_1: '#FF5733', //  Bright Red-Orange
    Worker_2: '#33FF57', //  Vivid Green
    Worker_3: '#3357FF', //  Strong Blue
    Worker_4: '#FF33A1', //  Pink
    Worker_5: '#FFFF33', //  Bright Yellow
    Worker_6: '#33FFF6', //  Cyan
    Worker_7: '#FF8C33', //  Orange
    Worker_8: '#8C33FF', //  Violet
    Worker_9: '#33FF8C', //  Mint Green
    Worker_10: '#5733FF', //  Deep Purple
    Worker_11: '#FF3333', //  Bright Red
    Worker_12: '#33FFFF', //  Light Cyan
    Worker_13: '#FF33FF', //  Magenta
    Worker_14: '#33FF33', //  Bright Green
    Worker_15: '#8CFF33', //  Lime Green
    Worker_16: '#FF338C', //  Hot Pink
    Worker_17: '#FF5733', //  Coral
    Worker_18: '#33FF57', //  Fresh Green
    Worker_19: '#FF33E5', //  Neon Pink
    Worker_20: '#3357FF', //  Electric Blue
    Worker_21: '#FFD733', //  Golden Yellow
    Worker_22: '#FF3399', //  Fuchsia
    Worker_23: '#33D7FF', //  Sky Blue
    Worker_24: '#B833FF', //  Deep Violet
    Worker_25: '#FF33D7', //  Strong Pink
    Worker_26: '#33FFBD', //  Teal
    Worker_27: '#57FF33', //  Spring Green
    Worker_28: '#FFBD33', //  Yellow-Orange
    Worker_29: '#FF33CC', //  Neon Purple
    Worker_30: '#33CCFF', //  Aqua Blue
    Worker_31: '#FF5733', //  Fiery Orange
    Worker_32: '#33FF99', //  Minty Green
    Worker_33: '#FF33BB', //  Shocking Pink
    Worker_34: '#338CFF', //  Sky Blue
    Worker_35: '#FF9933', //  Light Orange
    Worker_36: '#FF33A3', //  Bright Rose
    Worker_37: '#33FF77', //  Fresh Mint
    Worker_38: '#FF3388', //  Light Pink
    Worker_39: '#33E5FF', //  Vibrant Cyan
    Worker_40: '#FF3383', //  Pastel Pink
    Worker_41: '#33FFCC', //  Turquoise
    Worker_42: '#FF3388', //  Bold Pink
    Worker_43: '#8C33FF', //  Royal Purple
    Worker_44: '#FF88FF', //  Soft Pink
    Worker_45: '#33FFC4', //  Seafoam Green
    Worker_46: '#FF4433', //  Crimson
    Worker_47: '#33FFAA', //  Soft Mint
    Worker_48: '#FF9933', //  Warm Orange
    Worker_49: '#33B2FF', //  Ice Blue

};