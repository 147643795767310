import { createApi } from '@reduxjs/toolkit/query/react';
import { CampaignsExport } from 'models/Campaigns';
import { createBaseQueryWithAuth } from '../createBaseQueryWithAuth';

export const campaignRunAPI = createApi({
    reducerPath: 'campaignRun',
    baseQuery: createBaseQueryWithAuth(`${process.env.REACT_APP_HERMES_API_URL}/api/v1`),
    tagTypes: ['Create', 'Delete', 'Update'],
    endpoints: (build) => ({

        createCampaignRunExport: build.mutation<CampaignsExport, {
            body: {
                campaign_uuid: string;
                chunk_count: number;
                min_property_count: number;
                name: string;
            };
        }>({
            query: ({ body }) => ({
                url: '/campaign-runs',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Create']
        }),

        getAllRuns: build.query<any, void>({
            query: () => ({
                url: '/campaign-runs',
                method: 'GET',
            }),
        }),
        getChunksInRun: build.query<{
            run_uuid: string;
            chunk_id: number;
            worker_uuid: string;
            uprn_count: number;
        }[], { run_uuid: string; }>({
            query: ({ run_uuid }) => ({
                url: `/campaign-runs/${run_uuid}/chunks/`,
                method: 'GET',
            }),
        }),

        assignRunToWorker: build.mutation<{
            detail: string;
        }, {
            run_uuid: string;
            chunk_id: number;
            worker_uuid: string;
        }>({
            query: ({ run_uuid, chunk_id, worker_uuid }) => ({
                url: `/campaign-runs/${run_uuid}/chunks/${chunk_id}/worker/${worker_uuid}`,
                method: 'PATCH',
            }),
            invalidatesTags: ['Update']
        }),

        deleteRun: build.mutation<{
            detail: string,
            deleted: 0;
        }, {
            run_uuid: string;
        }>({
            query: ({ run_uuid, }) => ({
                url: `/campaign-runs/${run_uuid}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Delete']
        }),

    })
});