import { useMap } from 'app/providers/MapProvider';
import mapboxgl, { Expression } from 'mapbox-gl';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCampaign } from 'store/reducers/campaignSlice';
import { LayersName, colorRuleDots, floatToPercent, host } from '../utils';

export const usePenModelProperties = () => {
    const { map } = useMap();
    const { uuidForMap: campaignId } = useSelector(selectCampaign);

    const popup = new mapboxgl.Popup({
        closeButton: false,
    });

    useEffect(() => {
        if (!map || !campaignId) return;

        if (map.getSource('property')) {
            map.removeLayer(LayersName.Properties);
            map.removeSource('property');
        }

        // set up source
        map.addSource('property', {
            type: 'vector',
            tiles: [
                `${host}/api/v1/campaigns/${campaignId}/properties/tiles/{z}/{x}/{y}.mvt`,
            ],
            minzoom: 15,
            maxzoom: 24,
        });

        // set up point color 
        map.addLayer({
            id: LayersName.Properties,
            type: 'circle',
            source: 'property',
            'source-layer': 'default',
            paint: {
                'circle-color': colorRuleDots as Expression,
                'circle-opacity': 0.9,
                'circle-radius': {
                    'base': 1.75,
                    'stops': [
                        [12, 2],
                        [22, 90]
                    ]
                },
                'circle-stroke-width': 0.5,
                'circle-stroke-color': 'black'
            },
            layout: {
                'visibility': 'visible'
            },
            minzoom: 15,
            maxzoom: 24,
        });

        map.on('click', LayersName.Properties, (e: any) => {
            // @ts-ignore
            const props = e.features[0].properties;

            const uprn = props?.uprn;
            const classification_code = props?.classification_code;
            const building_number = props?.building_number;
            const postcode = props?.postcode;
            const pen_model_type = props?.pen_model_type;
            const predicated_pen = props?.predicated_pen;

            const titleHtml = `<h1>UPRN: <strong>${uprn}</strong></h1>`;
            const propsHtml = `
          <h2>Classification Code:<strong> ${classification_code}</strong></h2>
          <h2>Building Number: <strong>${building_number || '-'}</strong></h2>
          <h2>Postcode: <strong>${postcode}</strong></h2>
          <h2>Pen Model Type: <strong>${pen_model_type}</strong></h2>
          <h2>Predicated Pen: <strong>${floatToPercent(predicated_pen)}</strong></h2>
        `;
            const html = `<div class='popup'>
          ${titleHtml}
          ${propsHtml}
          </div>
        `;
            // Change the cursor style as a UI indicator.
            map.getCanvas().style.cursor = 'pointer';

            // Copy coordinates array.
            // @ts-ignore
            const coordinates = e.features[0].geometry.coordinates.slice();

            popup.setLngLat(coordinates).setHTML(html).addTo(map);
        });

        map.on('mouseenter', LayersName.Properties, () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', LayersName.Properties, () => {
            map.getCanvas().style.cursor = '';
        });
    }, [map, campaignId]);
};