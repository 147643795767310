import { NotificationType, useNotification } from 'hooks/useNotification';
import { campaignRunAPI } from 'services/Campaigns/CampaignsRunService';

interface Props {
    refetch?: any;
}

export const useDeleteCampaignRun = ({ refetch }: Props) => {

    const [deleteRun, { isSuccess: isDeleteRunSuccess, isError: isDeleteRunError }] = campaignRunAPI.useDeleteRunMutation();

    const handleDeleteCampaign = async (run_uuid: string) => {
        try {
            await deleteRun({ run_uuid });
            if (refetch) {
                refetch();
            }
        } catch (err) {
            console.error(err);
        }
    };

    useNotification({ type: NotificationType.SUCCESS, trigger: isDeleteRunSuccess, text: 'Run has been successfully removed!' });
    useNotification({ type: NotificationType.ERROR, trigger: isDeleteRunError, text: 'An error occurred while removing Run. If the issue persists, contact support for assistance.' });

    return {
        handler: (campaign_uuid: string) => handleDeleteCampaign(campaign_uuid),
        isError: isDeleteRunError,
        isSuccess: isDeleteRunSuccess
    };
};