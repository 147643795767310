import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Icons } from 'icons';
import { AddCampaignModal } from 'pages/CampaignPage/Forms/AddCampaignModal';
import { useState } from 'react';

export const AddCampaign = () => {
    const [isModalShown, setModalShown] = useState(false);

    return <>
        <Button size={ButtonSize.MD}
            palette={ButtonPalette.SECONDARY_GRAY}
            onClick={() => setModalShown(true)}
        >
            <Icons.Plus />
            Add new campaign
        </Button>

        {isModalShown && <AddCampaignModal onClose={() => setModalShown(false)} />}
    </>;
};